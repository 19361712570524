import { IkonCommand } from '../ikon-command';
import { Plugin } from '../plugin';

export class SpeechToTextPlugin implements Plugin {
  private recognition: any;
  private isRunning = false;

  constructor(ikonWorker: Worker) {
    console.debug('[SpeechToTextPlugin] Initialise');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) {
      console.debug('[SpeechToTextPlugin] SpeechRecognition is not supported');
      return;
    }

    this.recognition = new SpeechRecognition();

    this.recognition.lang = 'en-US';
    this.recognition.continuous = true;
    this.recognition.interimResults = true;

    // Set up the microphone with echo cancellation
    navigator.mediaDevices
      .getUserMedia({
        audio: { echoCancellation: true },
      })
      .then((stream) => {
        // Now the stream is set up with echo cancellation, connect it to the recognition
        console.debug('[SpeechToTextPlugin] Microphone connected to echo cancellation');
        this.recognition.audio = stream;
      })
      .catch((error) => {
        console.error('Error accessing the microphone', error);
      });

    this.recognition.onresult = (event: any) => {
      const result = event.results[event.results.length - 1];
      const text = result[0].transcript;
      console.log(`[SpeechToTextPlugin] Text: ${text}`);

      ikonWorker.postMessage({
        command: IkonCommand.SendChatText,
        text: text,
        complete: result.isFinal,
      });
    };

    this.recognition.onnomatch = () => {
      console.log('[SpeechToTextPlugin] No match');
    };

    this.recognition.onerror = (event: any) => {
      console.log(`[SpeechToTextPlugin] Error`, event.error);
    };

    this.recognition.onaudiostart = () => console.debug('[SpeechToTextPlugin] Listening');
    this.recognition.onaudioend = () => console.debug('[SpeechToTextPlugin] Not listening');

    this.recognition.onend = () => {
      if (this.isRunning) {
        console.debug('[SpeechToTextPlugin] Ended, attempting to restart');
        setTimeout(() => {
          this.recognition.start();
        }, 10);
      }
    };
  }

  async close() {
    // TODO: Implement close
    console.debug('[SpeechToTextPlugin] Close');
  }

  pause() {
    if (this.isRunning) {
      this.recognition?.stop();
      this.isRunning = false;
    }
  }

  resume() {
    if (!this.isRunning) {
      this.recognition?.start();
      this.isRunning = true;
    }
  }
}
