import { User, UserRole } from '@ikon-web/user-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../shared/data/store';

interface AuthState {
  isAuthenticated: boolean;
  isAnonymous?: boolean;
  isLoading: boolean;
  user?: User;
  error?: Error;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isAnonymous: false,
  isLoading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialized: (state, { payload: { user } }: PayloadAction<{ user?: User }>) => {
      state.isAuthenticated = !!user;
      state.isAnonymous = user?.role === UserRole.Anonymous;
      state.isLoading = false;
      state.user = user;
    },
    login: (state, { payload: { user } }: PayloadAction<{ user: User }>) => {
      state.isAuthenticated = true;
      state.isAnonymous = user?.role === UserRole.Anonymous;
      state.isLoading = false;
      state.user = user;
      state.error = undefined;
    },
    error: (state, { payload: { error } }: PayloadAction<{ error: any }>) => {
      state.error = error.err;
    },
  },
});

export const authReducer = authSlice.reducer;

export const { initialized: setAuthInitialized, login: authLogin, error: setAuthError } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;
export const selectAuthenticatedUserId = (state: RootState) => state.auth.user?.id;
