import { createSlice } from '@reduxjs/toolkit';
import { receiveContainer, receiveText } from './container.slice';

const containerUpdatesSlice = createSlice({
  name: 'containerUpdates',
  initialState: { count: 0, forceCount: 0 },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(receiveContainer, (state, action) => {
        if (action.payload.isFromCurrentUser) {
          state.forceCount += 1;
          return;
        }

        if (action.payload.container.isUpdate) return;

        state.count += 1;
      })
      .addCase(receiveText, (state) => {
        state.count += 1;
      });
  },
});

export const containerUpdatesReducer = containerUpdatesSlice.reducer;
