import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { UiContainer } from '../../components/ikon/ui-container';
import { selectHeaderContainerIds } from '../../shared/data/container.slice';

export function ChatHeaderContainers() {
  const rootContainerIds = useSelector(selectHeaderContainerIds);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', maxWidth: '800px', margin: '0 auto' }}>
        {rootContainerIds.map((rootContainerId) => (
          <UiContainer key={rootContainerId} containerId={rootContainerId as string} />
        ))}
      </Box>
    </Box>
  );
}
