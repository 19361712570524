import CheckIcon from '@mui/icons-material/Check';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useContext } from 'react';
import { BottomMenu } from '../../../../components/bottom-menu';
import { ThemeSelectorContext } from '../../../../shared/context/theme-selector.context';
import { SettingsThemeMenuButton } from './settings-theme-menu-button';

export function SettingsThemeMobile() {
  const themeSelector = useContext(ThemeSelectorContext);

  const selectAppearance = async (event: React.MouseEvent<HTMLDivElement>, code: string) => {
    event.stopPropagation();
    themeSelector.selectTheme(code);
  };

  return (
    <BottomMenu button={<SettingsThemeMenuButton />}>
      <Stack direction="column" spacing={0.5} useFlexGap>
        <List>
          {themeSelector.availableThemes.map((theme) => (
            <ListItem
              key={theme.code}
              disablePadding
              sx={{
                marginBottom: '4px',
              }}
            >
              <ListItemButton
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  borderRadius: '12px',
                  p: 1,
                  color: 'text.secondary',
                  '&:hover': {
                    backgroundColor: 'transparent.hover',
                  },
                  '&.Mui-selected': {
                    backgroundColor: 'transparent.neutral.selected',
                    color: 'onSurface.highEmphasis',
                  },
                  gap: 1,
                  justifyContent: 'space-between',
                  minWidth: '150px',
                }}
                selected={themeSelector.theme === theme.code}
                onClick={(event) => selectAppearance(event, theme.code)}
              >
                <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>{theme.name}</ListItemText>
                {themeSelector.theme === theme.code && <CheckIcon />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Stack>
    </BottomMenu>
  );
}
