import { isRoomLocal } from '@ikon-web/space-types';
import { useMemo } from 'react';
import { api } from '../api/api';
import { useSpace } from './use-space';

export function useRooms() {
  const space = useSpace();
  const { data: roomTemplatesResponse } = api.useGetRoomTemplatesBySpaceQuery(space.id);
  const { data: roomsResponse, isFetching } = api.useGetRoomsQuery({ space: space.id });

  return useMemo(() => {
    const rooms = [];
    const mainRooms = [];

    if (roomTemplatesResponse && roomsResponse) {
      for (const room of roomsResponse.results) {
        if (!isRoomLocal(room.mode) && roomTemplatesResponse.results.some((template) => template.mainRoom && template.id === room.roomTemplate)) {
          mainRooms.push(room);
        } else {
          rooms.push(room);
        }
      }
    }

    return { rooms, mainRooms, isFetching };
  }, [roomTemplatesResponse, roomsResponse, isFetching]);
}
