import {
  UiAutocomplete,
  UiCheckbox,
  UiContainerModel,
  UiContainerOrElement,
  UiElementBadge,
  UiElementButton,
  UiElementHeader,
  UiElementIcon,
  UiElementIconButton,
  UiElementImage,
  UiElementInputText,
  UiElementMap,
  UiElementMaterialSymbol,
  UiElementProgressBar,
  UiElementText,
  UiElementType,
  UiElementVegaChart,
} from '@ikon-web/ikon-client';
import { UiAutocompleteInput } from './ui-autocomplete';
import { UiBadge } from './ui-badge';
import { UiButton } from './ui-button';
import { UiCheckboxInput } from './ui-checkbox';
import { UiContainer } from './ui-container';
import { UiContainerButton } from './ui-container-button';
import { UiContainerCard } from './ui-container-card';
import { UiContainerCarousel } from './ui-container-carousel';
import { UiContainerColumn } from './ui-container-column';
import { UiContainerDefinitionList } from './ui-container-definition-list';
import { UiContainerFileUpload } from './ui-container-file-upload';
import { UiContainerRight } from './ui-container-right';
import { UiContainerRow } from './ui-container-row';
import { UiHeader } from './ui-header';
import { UiIcon } from './ui-icon';
import { UiIconButton } from './ui-icon-button';
import { UiImage } from './ui-image';
import { UiInputText } from './ui-input-text';
import { UiMap } from './ui-map';
import { UiMaterialSymbol } from './ui-material-symbol';
import { UiProgressBar } from './ui-progress-bar';
import { UiSeparator } from './ui-separator';
import { UiText } from './ui-text';
import { UiVegaChart } from './ui-vega-chart';

export function UiElement({ element, isCard = false, myMessage = false, disabled = false }: { element: UiContainerOrElement; isCard?: boolean; myMessage?: boolean; disabled?: boolean }) {
  switch (element.type) {
    case 'container':
      return <UiContainer containerId={element.containerId} myMessage={myMessage} disabled={disabled} />;
    case 'row':
      return <UiContainerRow container={element as UiContainerModel} isCard={isCard} myMessage={myMessage} disabled={disabled} />;
    case 'column':
      return <UiContainerColumn container={element as UiContainerModel} isCard={isCard} myMessage={myMessage} disabled={disabled} />;
    case 'right':
      return <UiContainerRight container={element as UiContainerModel} isCard={isCard} myMessage={myMessage} disabled={disabled} />;
    case 'card':
      return <UiContainerCard container={element as UiContainerModel} myMessage={myMessage} disabled={disabled} />;
    case 'file-upload':
      return <UiContainerFileUpload container={element as UiContainerModel} disabled={disabled} />;
    case 'carousel':
      return <UiContainerCarousel container={element as UiContainerModel} disabled={disabled} />;
    case 'definition-list':
      return <UiContainerDefinitionList container={element as UiContainerModel} />;
    case 'button':
      return <UiContainerButton container={element as UiContainerModel} myMessage={myMessage} disabled={disabled} />;
    case UiElementType.Header:
      return <UiHeader element={element.element as UiElementHeader} />;
    case UiElementType.Text:
      return <UiText element={element.element as UiElementText} myMessage={myMessage} disabled={disabled} />;
    case UiElementType.Image:
      return <UiImage element={element.element as UiElementImage} isCard={isCard} />;
    case UiElementType.Badge:
      return <UiBadge element={element.element as UiElementBadge} disabled={disabled} />;
    case UiElementType.Button:
      return <UiButton element={element.element as UiElementButton} disabled={disabled} />;
    case UiElementType.IconButton:
      return <UiIconButton element={element.element as UiElementIconButton} disabled={disabled} />;
    case UiElementType.Icon:
      return <UiIcon element={element.element as UiElementIcon} />;
    case UiElementType.MaterialSymbol:
      return <UiMaterialSymbol element={element.element as UiElementMaterialSymbol} disabled={disabled} />;
    case UiElementType.Map:
      return <UiMap element={element.element as UiElementMap} />;
    case UiElementType.Separator:
      return <UiSeparator />;
    case UiElementType.InputText:
      return <UiInputText element={element.element as UiElementInputText} disabled={disabled} />;
    case UiElementType.ProgressBar:
      return <UiProgressBar element={element.element as UiElementProgressBar} />;
    case UiElementType.VegaChart:
      return <UiVegaChart element={element.element as UiElementVegaChart} />;
    case UiElementType.Autocomplete:
      return <UiAutocompleteInput element={element.element as UiAutocomplete} disabled={disabled} />;
    case UiElementType.Checkbox:
      return <UiCheckboxInput element={element.element as UiCheckbox} disabled={disabled} />;
  }

  return null;
}
