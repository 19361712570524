import { Notification, NotificationState } from '@ikon-web/notification-types';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { timeFromNow } from '../../shared/utils/time.utils';

export function NotificationLine(props: { notification: Notification }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '12px',
        transition: 'background 200ms ease',
        '&:hover': {
          backgroundColor: 'transparent.hover',
        },
      }}
    >
      <Stack direction="row" spacing={1} useFlexGap sx={{ alignItems: 'center', py: 1.5, px: 1, width: '100%' }}>
        <IconButton size="small" sx={{ backgroundColor: 'primary.main', width: '24px', height: '24px' }}>
          <NotificationsActiveOutlinedIcon style={{ width: '16px', height: '16px' }} />
        </IconButton>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{
            flex: '1 0 0',
            justifyContent: 'space-between',
            color: props.notification.state === NotificationState.Unread ? 'text.primary' : 'text.secondary',
          }}
        >
          <Typography variant="body2">{props.notification.message}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '10px', lineHeight: 2 }}>
            {timeFromNow(props.notification.createdAt)}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
