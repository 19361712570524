import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { ListItemIcon, ListItemText } from '@mui/material';
import * as React from 'react';
import { MouseEvent } from 'react';
import { MenuItem } from '../../../../components/menu/menu-item';

export function SettingsMenuButton(props: { handleOpen?: (event: MouseEvent<HTMLElement>) => void }) {
  return (
    <MenuItem onClick={props.handleOpen}>
      <ListItemIcon sx={{ minWidth: 0 }}>
        <SettingsOutlinedIcon />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>Settings</ListItemText>
    </MenuItem>
  );
}
