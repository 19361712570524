import { UIButtonVariant, UIElementLabels } from '@ikon-web/event-shared';
import { UiElementType } from '@ikon-web/ikon-client';
import { Button, IconButton } from '@mui/material';
import { MouseEvent, useMemo } from 'react';
import { useIkon } from '../../shared/hooks/use-ikon';
import { UiContainerElements } from './ui-container-elements';

export function UiContainerButton({ container, myMessage, disabled }: { container: any; myMessage: boolean; disabled?: boolean }) {
  const ikon = useIkon();

  function handleClick(event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    event.stopPropagation();
    ikon.sendAction(container.actionId);
  }

  const isDisabled = disabled || container.labels?.includes(UIElementLabels.Disabled);

  const variant = useMemo(() => {
    switch (container.variant) {
      case UIButtonVariant.Text:
        return 'text';
      case UIButtonVariant.Contained:
        return 'contained';
      default:
        return 'outlined';
    }
  }, [container.variant]);

  if (container.elements.at(0)?.type === UiElementType.MaterialSymbol) {
    return (
      <IconButton size={container.labels.includes(UIElementLabels.SizeSmall) ? 'small' : undefined} disabled={isDisabled} onClick={handleClick}>
        <UiContainerElements container={container} myMessage={myMessage} disabled={disabled} />
      </IconButton>
    );
  }

  return (
    <Button variant={variant} size={container.labels.includes(UIElementLabels.SizeSmall) ? 'small' : undefined} disabled={isDisabled} onClick={handleClick}>
      <UiContainerElements container={container} myMessage={myMessage} disabled={disabled} />
    </Button>
  );
}
