import { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

export function Fade({ show, children }: { show: boolean; children: ReactNode }) {
  return (
    <CSSTransition in={show} timeout={300} classNames="fade" unmountOnExit>
      {children}
    </CSSTransition>
  );
}
