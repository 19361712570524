import { User } from '@ikon-web/user-types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LOCAL_STORAGE_AUTH_TOKEN } from '../constants';
import { baseUrl } from '../utils/api.utils';
import { isIframe } from '../utils/iframe.utils';

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  credentials: 'include',
  prepareHeaders: (headers) => {
    if (!isIframe) return headers;
    const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN);
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    authenticateAnonymous: builder.mutation<{ token: string; user: User }, { deviceId: string; space: string }>({
      query: (body) => ({
        method: 'POST',
        url: `/meet/1/auth/anonymous`,
        body,
      }),
    }),
    authenticateUsername: builder.mutation<User, { username: string; password: string }>({
      query: (body) => ({
        method: 'POST',
        url: `/meet/1/auth/username`,
        body,
      }),
    }),
    authenticateEmail: builder.mutation<void, { email: string; useCode?: boolean }>({
      query: (body) => ({
        method: 'POST',
        url: `/meet/1/auth/email`,
        body,
      }),
    }),
    authenticateEmailCode: builder.mutation<{ token: string }, { code: string }>({
      query: (body) => ({
        method: 'POST',
        url: `/meet/1/auth/email/code`,
        body,
      }),
    }),

    getCurrentUser: builder.query<User, void>({
      query: () => `/meet/1/users/me`,
    }),
  }),
});
