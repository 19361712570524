import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { UiContainer } from '../../components/ikon/ui-container';
import { selectDebugOverlayContainerIds } from '../../shared/data/container.slice';
import { closeDebugOverlay, selectDebugOverlayOpen } from './debug-overlay.slice';

export function DebugOverlay() {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectDebugOverlayOpen);
  const containerIds = useSelector(selectDebugOverlayContainerIds);

  return (
    <Dialog open={isOpen} onClose={() => dispatch(closeDebugOverlay())}>
      <DialogContent sx={{ backgroundColor: 'surface.background.overlaySolid' }}>
        {containerIds.map((containerId) => (
          <UiContainer key={containerId} containerId={containerId} myMessage={false} />
        ))}
      </DialogContent>
    </Dialog>
  );
}
