import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, IconButton, Link, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { differenceInHours } from 'date-fns/differenceInHours';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentRoomState } from '../features/room/room.slice';
import { api } from '../shared/api/api';
import { LOCAL_STORAGE_DISCLAIMER_SHOWN } from '../shared/constants';
import { useSpace } from '../shared/hooks/use-space';
import { Fade } from './fade';
import { PrivacyPolicyModal } from './privacy-policy-modal';
import { TermsOfServiceModal } from './terms-of-service-modal';

export function Disclaimer() {
  const space = useSpace();
  const { room } = useSelector(selectCurrentRoomState);
  const { data: roomTemplate } = api.useGetRoomTemplateByIdQuery(room?.roomTemplate, { skip: !room?.roomTemplate });

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [termsOfServiceOpen, setTermsOfServiceOpen] = useState(false);

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout;
    if (roomTemplate?.mainRoom) {
      const disclaimerShown = localStorage.getItem(LOCAL_STORAGE_DISCLAIMER_SHOWN);
      if (!disclaimerShown || differenceInHours(new Date(), new Date(JSON.parse(disclaimerShown))) > 24) {
        localStorage.setItem(LOCAL_STORAGE_DISCLAIMER_SHOWN, JSON.stringify(Date.now()));
        setShowDisclaimer(true);
        timeout = setTimeout(() => {
          setShowDisclaimer(false);
        }, 10000);
      }
    }

    return () => {
      if (timeout) clearTimeout(timeout);
      setShowDisclaimer(false);
    };
  }, [roomTemplate, room]);

  return (
    <>
      <Fade show={showDisclaimer}>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            mx: 2,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '700px',
              zIndex: 1000,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{
                alignItems: 'center',
                color: 'text.secondary',
                backgroundColor: 'surface.background.overlayTransparent',
                backdropFilter: 'blur(24px)',
                borderRadius: '14px',
                p: 1.5,
              }}
            >
              <InfoOutlinedIcon sx={{ display: { xs: 'none', sm: 'block' } }} />
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ fontSize: '10px', lineHeight: '14px' }}>
                  By continuing to interact with AI Agent you agree to our{' '}
                  <Link onClick={() => setTermsOfServiceOpen(true)} sx={{ cursor: 'pointer' }}>
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link onClick={() => setPrivacyPolicyOpen(true)} sx={{ cursor: 'pointer' }}>
                    Privacy Policy
                  </Link>
                  .
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '10px', lineHeight: '14px' }}>
                  {space.customisation.disclaimer}
                </Typography>
              </Box>
              <IconButton onClick={() => setShowDisclaimer(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Fade>

      <TermsOfServiceModal open={termsOfServiceOpen} close={() => setTermsOfServiceOpen(false)} />
      <PrivacyPolicyModal open={privacyPolicyOpen} close={() => setPrivacyPolicyOpen(false)} />
    </>
  );
}
