export function Overlay() {
  // const isOpen = useSelector(selectPreviewScreenOpen);
  // const elements: any[] = useSelector(selectPreviewScreenElements);
  // const dispatch = useDispatch();
  //
  // const handleClose = useCallback(() => {
  //   dispatch(screenSetClose({ name: 'Preview' }));
  // }, [dispatch]);
  //
  // useEffect(() => {
  //   if (elements.at(0)?.opcode === Opcode.UI_FILE) {
  //     const frame = elements[0].frame as UiFile;
  //     if (frame.Mime.startsWith('image/')) return;
  //
  //     // TODO: Fix signed download url cors issue, missing header Cross-Origin-Resource-Policy: cross-origin
  //     window.open(frame.Url, '_blank');
  //     handleClose();
  //   }
  // }, [elements, handleClose]);
  //
  // if (elements.at(0)?.opcode === Opcode.UI_FILE) {
  //   const frame = elements[0].frame as UiFile;
  //   if (frame.Mime.startsWith('image/')) {
  //     return (
  //       <Dialog maxWidth="md" open={isOpen} onClose={handleClose}>
  //         <DialogTitle>{frame.Name}</DialogTitle>
  //
  //         <DialogContent>{frame.Mime.startsWith('image/') && <Box component="img" src={frame.Url} alt={frame.Name} />}</DialogContent>
  //
  //         <DialogActions>
  //           <Button onClick={handleClose}>Close</Button>
  //         </DialogActions>
  //       </Dialog>
  //     );
  //   }
  // }

  return null;
}
