import { SpacePublic } from '@ikon-web/space-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../shared/data/store';

const initialState: { space?: SpacePublic } = {
  space: undefined,
};

const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    setSpace: (state, { payload: { space } }: PayloadAction<{ space: SpacePublic }>) => {
      state.space = space;
    },
    reset: () => initialState,
  },
});

export const spaceReducer = spaceSlice.reducer;

export const { setSpace, reset: resetSpace } = spaceSlice.actions;

export const selectSpace = (state: RootState) => state.space.space;
