import { FormatDistanceFn, formatDistanceToNow } from 'date-fns';

const formatDistance: FormatDistanceFn = (token, count) => {
  switch (token) {
    case 'lessThanXSeconds':
      return `${count}s`;
    case 'xSeconds':
      return `${count}s`;
    case 'halfAMinute':
      return '30s';
    case 'lessThanXMinutes':
      return `${count}m`;
    case 'xMinutes':
      return `${count}m`;
    case 'aboutXHours':
      return `${count}h`;
    case 'xHours':
      return `${count}h`;
    case 'xDays':
      return `${count}d`;
    case 'aboutXWeeks':
      return `${count}w`;
    case 'xWeeks':
      return `${count}w`;
    case 'aboutXMonths':
      return `${count}m`;
    case 'xMonths':
      return `${count}m`;
    case 'aboutXYears':
      return `${count}y`;
    case 'xYears':
      return `${count}y`;
    case 'overXYears':
      return `${count}y`;
    case 'almostXYears':
      return `${count}y`;
    default:
      return '';
  }
};

export function timeFromNow(date: Date | string) {
  return formatDistanceToNow(date, { locale: { formatDistance } });
}
