import { useEffect, useState } from 'react';

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{ width?: number; height?: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function update() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    update();

    window.addEventListener('resize', update);
    return () => {
      window.removeEventListener('resize', update);
    };
  }, []);

  return windowSize;
}
