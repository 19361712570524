import { useEffect, useState } from 'react';

export function usePageVisibility(callback?: (isVisible: boolean) => void) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handler = () => {
      const newIsVisible = document.visibilityState === 'visible';
      setIsVisible(newIsVisible);
      if (callback) callback(newIsVisible);
    };

    document.addEventListener('visibilitychange', handler);
    return () => document.removeEventListener('visibilitychange', handler);
  }, [callback]);

  return isVisible;
}
