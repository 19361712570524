import { Profile } from '@ikon-web/space-types';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../shared/data/store';

const initialState: { space?: string; profile?: Profile } = {
  space: undefined,
  profile: undefined,
};

const currentProfileSlice = createSlice({
  name: 'currentProfile',
  initialState,
  reducers: {
    setProfile: (state, { payload: { space, profile } }: PayloadAction<{ space: string; profile: Profile }>) => {
      state.space = space;
      state.profile = profile;
    },
    reset: () => initialState,
  },
});

export const currentProfileReducer = currentProfileSlice.reducer;

export const { setProfile, reset: resetProfile } = currentProfileSlice.actions;

export const selectCurrentProfile = (state: RootState) => state.currentProfile.profile;
export const selectCurrentProfileAvatar = createSelector(selectCurrentProfile, (profile) => {
  if (!profile?.assets?.length) return;

  let avatar = profile.assets.find((asset) => asset.type === 'profile-avatar');
  if (!avatar) avatar = profile.assets.find((asset) => asset.type === 'profile-default-avatar');
  if (!avatar) return;

  return avatar.url;
});
