import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navigation } from '../../components/navigation';
import { api } from '../../shared/api/api';
import { useAuth } from '../../shared/hooks/use-auth';
import { getDeviceId } from '../../shared/services/device-id';
import { ikonServerHost } from '../../shared/utils/env.utils';

export function RoomLocalPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [createRoom] = api.useCreateRoomMutation();

  useEffect(() => {
    async function process() {
      const room = await createRoom({
        mode: 'local',
        ikonServerHost: ikonServerHost,
        deviceId: getDeviceId(user.id),
      }).unwrap();
      navigate(`/rooms/${room.code}`, { state: { createdRoom: true } });
    }

    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Navigation />
      <h1 className="text-center mt-5">Ikon</h1>
      <p>Loading...</p>
    </>
  );
}
