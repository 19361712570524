import { IkonController } from '@ikon-web/ikon-client';
import { ReactNode, useState } from 'react';
import { IkonContext } from './ikon.context';

export function IkonProvider(opts: { children?: ReactNode }) {
  const [ikonController, setIkonController] = useState<IkonController | undefined>();

  function sendAction(actionId: string) {
    if (actionId) ikonController?.callAction(actionId);
  }

  function sendTextAction(actionId: string, value: string) {
    if (actionId) ikonController?.callTextUpdateAction(actionId, value);
  }

  function sendFileUploadAction(actionId: string, file: File) {
    if (actionId) ikonController?.callFileUploadAction(actionId, file);
  }

  function enableAudio(enabled: boolean) {
    ikonController?.enableAudio(enabled);
  }

  return <IkonContext.Provider value={{ sendAction, sendTextAction, sendFileUploadAction, setIkonController, enableAudio }}>{opts.children}</IkonContext.Provider>;
}
