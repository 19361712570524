import { Room, RoomMode } from '@ikon-web/space-types';
import { api } from '../../shared/api/api';

export function RoomName({ room }: { room: Room }) {
  const { data: roomTemplate, isLoading } = api.useGetRoomTemplateByIdQuery(room.roomTemplate);

  if (isLoading) return null;

  return (
    <span>
      {roomTemplate?.name || room.roomTemplateTitle || room.roomTemplateSlug || room.code}
      {room.mode === RoomMode.Local ? ' (Local)' : ''}
      {room.mode === RoomMode.LocalProxy ? ' (Local Proxy)' : ''}
    </span>
  );
}
