import { useDispatch, useSelector } from 'react-redux';
import { selectHasInteraction, setHasInteraction } from '../data/interaction.slice';

export function useInteraction() {
  const dispatch = useDispatch();
  const hasInteraction = useSelector(selectHasInteraction);

  return {
    hasInteraction,
    setHasInteraction: () => dispatch(setHasInteraction()),
  };
}
