import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { UiContainer } from '../../components/ikon/ui-container';
import { HAS_DEBUG_CONTAINER } from '../../shared/constants';
import { selectContainerById } from '../../shared/data/container.slice';
import { RootState } from '../../shared/data/store';
import { selectAuthenticatedUserId } from '../auth/auth.slice';

export function ChatRootContainer({ containerId, centerMessages }: { containerId?: string; centerMessages?: boolean }) {
  const container = useSelector((state: RootState) => selectContainerById(state, containerId));
  const userId = useSelector(selectAuthenticatedUserId);

  if (!container) return null;

  const myMessage = container.userId === userId;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: myMessage ? 'end' : 'start',
        marginLeft: !centerMessages && myMessage ? { xs: '16px', sm: '40px' } : null,
        marginRight: !centerMessages && !myMessage ? { xs: '16px', sm: '40px' } : null,
      }}
    >
      {HAS_DEBUG_CONTAINER ? (
        <div style={{ width: '100%' }}>
          <small style={{ paddingLeft: '12px' }}>
            c: {container.containerId} g: {container.groupId}
          </small>
          <UiContainer containerId={containerId} myMessage={myMessage} disabled={container.isHistory} />
        </div>
      ) : (
        <UiContainer containerId={containerId} myMessage={myMessage} disabled={container.isHistory} />
      )}
    </Box>
  );
}
