import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../shared/data/store';

const initialState: { open: boolean } = { open: false };

const secondScreenContainerSlice = createSlice({
  name: 'secondScreenContainer',
  initialState,
  reducers: {
    open: (state) => {
      state.open = true;
    },
    close: (state) => {
      state.open = false;
    },
    reset: () => initialState,
  },
});

export const secondScreenContainerReducer = secondScreenContainerSlice.reducer;

export const { open: openSecondScreen, close: closeSecondScreen, reset: resetSecondScreenContainer } = secondScreenContainerSlice.actions;

export const selectSecondScreenOpen = (state: RootState) => state.secondScreenContainer.open;
