import { UiElementMap } from '@ikon-web/ikon-client';
import { Map } from '../map/map';
import { MapLoader } from '../map/map-loader';

export function UiMap({ element }: { element: UiElementMap }) {
  return (
    <MapLoader>
      <Map marker={element.marker} markers={element.markers}></Map>
    </MapLoader>
  );
}
