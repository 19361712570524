import { Box, Button, Modal } from '@mui/material';
import Stack from '@mui/material/Stack';
import { cloneElement, ReactElement, useState } from 'react';

export function BottomMenu({ button, children }: { button: ReactElement; children: ReactElement }) {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    setVisible(true);
    setTimeout(() => setOpen(true), 10);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setVisible(false), 200); // Match the transition duration
  };

  return (
    <>
      {cloneElement(button, { handleOpen })}

      <Modal open={visible} onClose={handleClose}>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: 'background.default',
            borderRadius: '24px 24px 0 0',
            p: 2,
            outline: 0,
            transition: 'transform 0.2s ease-in-out',
            transform: open ? 'translateY(0)' : 'translateY(100%)',
          }}
        >
          <Stack direction="column" gap={4} useFlexGap>
            <Box>{children}</Box>

            <Button onClick={handleClose}>Close</Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
