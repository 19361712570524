import { getSpaceLogo, Room, RoomTemplatePublic, RoomTemplateType, SpacePublic } from '@ikon-web/space-types';
import { User } from '@ikon-web/user-types';
import { LANGUAGES } from '@ikon-web/utils';
import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box, Button, ClickAwayListener, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, Switch, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useContext, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { MenuItem } from '../../../../components/menu/menu-item';
import { PrivacyPolicyModal } from '../../../../components/privacy-policy-modal';
import { TermsOfServiceModal } from '../../../../components/terms-of-service-modal';
import { api } from '../../../../shared/api/api';
import { HAS_DEBUG_OVERLAY } from '../../../../shared/constants';
import { ThemeSelectorContext } from '../../../../shared/context/theme-selector.context';
import { useIkon } from '../../../../shared/hooks/use-ikon';
import { useRooms } from '../../../../shared/hooks/use-rooms';
import { useSidebar } from '../../../../shared/hooks/use-sidebar';
import { useSpace } from '../../../../shared/hooks/use-space';
import { featureTemporalRooms } from '../../../../shared/utils/env.utils';
import { isIframe } from '../../../../shared/utils/iframe.utils';
import { openDebugOverlay } from '../../../debug-overlay/debug-overlay.slice';
import { selectCurrentProfile } from '../../../profile/current-profile.slice';
import { RoomName } from '../../../room/room-name';
import { SettingsMenuButton } from './settings-menu-button';

export function SpaceSidebarDesktop(props: {
  space: SpacePublic;
  rooms: Room[];
  roomTemplates: RoomTemplatePublic[];
  activeRoomCode: string;
  user: User;
  onRoomCreate: (roomTemplate: RoomTemplatePublic, local?: boolean) => void;
}) {
  const dispatch = useDispatch();
  const space = useSpace();
  const ikon = useIkon();
  const { sidebarOpen, setSidebarOpen } = useSidebar();
  const themeSelector = useContext(ThemeSelectorContext);
  const { mainRooms } = useRooms();
  const profile = useSelector(selectCurrentProfile);

  const [appearanceOpen, setAppearanceOpen] = useState(false);

  const [updateProfile] = api.useUpdateProfileMutation();

  const temporalRoomTemplates = useMemo(() => props.roomTemplates?.filter((rt) => rt.type === RoomTemplateType.Temporal).map((roomTemplate) => roomTemplate.id) || [], [props.roomTemplates]);

  const [enableAudio, setEnableAudio] = useState(true);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [termsOfServiceOpen, setTermsOfServiceOpen] = useState(false);

  const settingsRef = useRef();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [subAnchorEl, setSubAnchorEl] = useState<null | HTMLElement>(null);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const handleSettingsOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSettingsOpen((prevOpen) => !prevOpen);
  };

  const [languageOpen, setLanguageOpen] = useState(false);
  const handleLanguageOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setSubAnchorEl(event.currentTarget);
    setLanguageOpen((prevOpen) => !prevOpen);
  };

  const handleAppearanceOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setSubAnchorEl(event.currentTarget);
    setAppearanceOpen((prevOpen) => !prevOpen);
  };

  const selectLanguage = async (event: React.MouseEvent<HTMLDivElement>, language: string) => {
    event.stopPropagation();
    await updateProfile({ id: profile.id, form: { language } }).unwrap();
    setLanguageOpen(false);
  };

  const selectAppearance = async (event: React.MouseEvent<HTMLDivElement>, code: string) => {
    event.stopPropagation();
    themeSelector.selectTheme(code);
    setAppearanceOpen(false);
  };

  const closeAllMenus = () => {
    setLanguageOpen(false);
    setSettingsOpen(false);
    setSidebarOpen(false);
  };

  const handleRoomTemplateClick = (roomTemplate: RoomTemplatePublic) => {
    setSidebarOpen(false);
    props.onRoomCreate(roomTemplate, false);
  };

  const handleOpenFullExperience = () => {
    window.open(`/`, '_blank');
  };

  const logoUrl = getSpaceLogo(space)?.url;

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          if (sidebarOpen) setSidebarOpen(false);
        }}
      >
        <Box>
          <Box sx={{ position: 'absolute', left: '24px', top: '0', bottom: '0', zIndex: 10000, display: sidebarOpen ? 'block' : 'none' }}>
            <Box sx={{ width: '240px', height: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  padding: 0.5,
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  borderRadius: '16px',
                  backgroundColor: 'background.paper',
                  overflowY: 'auto',
                  scrollbarWidth: 'thin',
                  boxShadow: '0px 24px 48px -12px rgba(0, 0, 0, 0.15)',
                  backdropFilter: 'blur(32px)',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <List sx={{ width: '100%', p: 0 }}>
                    <ListItem sx={{ display: 'flex', gap: 1 }}>
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <Box component="img" src={logoUrl} sx={{ maxWidth: '24px', maxHeight: '24px', objectFit: 'contain' }} />
                      </ListItemIcon>
                      <ListItemText>{space?.name}</ListItemText>
                    </ListItem>

                    <Divider sx={{ my: 0.5 }} />

                    {mainRooms?.length > 0 && (
                      <ListItem
                        key={mainRooms[0].id}
                        disablePadding
                        sx={{
                          marginBottom: '4px',
                        }}
                      >
                        <MenuItem
                          component={RouterLink}
                          to={mainRooms[0].code}
                          state={{ interaction: true }}
                          selected={mainRooms[0].code === props.activeRoomCode}
                          onClick={() => setSidebarOpen(false)}
                        >
                          <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>
                            <RoomName room={mainRooms[0]} />
                          </ListItemText>
                        </MenuItem>
                      </ListItem>
                    )}
                    {props.rooms
                      ?.filter((room) => !mainRooms.some((mr) => mr.code === room.code) && !temporalRoomTemplates.includes(room.roomTemplate))
                      .map((room) => (
                        <ListItem
                          key={room.id}
                          disablePadding
                          sx={{
                            marginBottom: '4px',
                          }}
                        >
                          <MenuItem component={RouterLink} to={room.code} state={{ interaction: true }} selected={room.code === props.activeRoomCode} onClick={() => setSidebarOpen(false)}>
                            <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>
                              <RoomName room={room} />
                            </ListItemText>
                          </MenuItem>
                        </ListItem>
                      ))}

                    {featureTemporalRooms && (
                      <>
                        <Divider sx={{ my: 0.5 }} />
                        {props.roomTemplates
                          ?.filter((roomTemplate) => roomTemplate.type === RoomTemplateType.Temporal)
                          .map((roomTemplate) => (
                            <ListItem
                              key={roomTemplate.id}
                              disablePadding
                              sx={{
                                marginBottom: '4px',
                              }}
                            >
                              <MenuItem onClick={() => handleRoomTemplateClick(roomTemplate)}>
                                <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>{roomTemplate.name}</ListItemText>
                              </MenuItem>
                            </ListItem>
                          ))}
                      </>
                    )}
                  </List>
                </Box>

                <List sx={{ width: '100%', p: 0 }}>
                  <ListItem
                    disablePadding
                    sx={{
                      marginBottom: '4px',
                      '.MuiListItemSecondaryAction-root': {
                        display: 'inline-flex',
                      },
                    }}
                    secondaryAction={<ChevronRightIcon />}
                  >
                    <SettingsMenuButton handleOpen={handleSettingsOpen} />
                  </ListItem>
                  {!!isIframe && (
                    <ListItem
                      disablePadding
                      sx={{
                        marginBottom: '4px',
                      }}
                    >
                      <MenuItem onClick={handleOpenFullExperience}>
                        <ListItemIcon sx={{ minWidth: 0 }}>
                          <OpenInBrowserIcon />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>Launch full experience</ListItemText>
                      </MenuItem>
                    </ListItem>
                  )}
                </List>
              </Box>
            </Box>
          </Box>

          <Menu
            open={settingsOpen}
            onClose={() => setSettingsOpen(false)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            elevation={1}
            sx={{ background: 'transparent', marginLeft: 1 }}
          >
            <Stack
              direction="column"
              spacing={0.5}
              useFlexGap
              sx={{
                p: 0.5,
              }}
              ref={settingsRef}
            >
              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                sx={{
                  p: 1,
                  color: 'text.secondary',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'transparent.hover',
                  },
                }}
                onClick={handleLanguageOpen}
              >
                <LanguageIcon />
                <Typography variant="body2" sx={{ flex: 1 }}>
                  Language
                </Typography>
                <Typography variant="caption">{profile?.language ? LANGUAGES[profile.language]?.name || profile.language : 'Dynamic'}</Typography>
                <ChevronRightIcon />
              </Stack>

              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                sx={{
                  p: 1,
                  color: 'text.secondary',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'transparent.hover',
                  },
                }}
                onClick={handleAppearanceOpen}
              >
                <DarkModeOutlinedIcon />
                <Typography variant="body2" sx={{ flex: 1 }}>
                  Appearance
                </Typography>
                <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                  {themeSelector.theme}
                </Typography>
                <ChevronRightIcon />
              </Stack>

              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                sx={{
                  p: 1,
                  color: 'text.secondary',
                }}
              >
                <VolumeUpIcon style={{ width: '20px', height: '20px' }} />
                <Typography variant="body2" sx={{ flex: 1 }}>
                  Audio
                </Typography>
                <Switch
                  size="small"
                  checked={enableAudio}
                  onChange={(event) => {
                    setEnableAudio(event.target.checked);
                    ikon.enableAudio(event.target.checked);
                  }}
                />
              </Stack>

              <Divider />

              <Stack
                direction="row"
                spacing={1}
                useFlexGap
                sx={{
                  p: 1,
                  color: 'text.secondary',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    closeAllMenus();
                    setTermsOfServiceOpen(true);
                  }}
                  sx={{
                    fontSize: '10px',
                    lineHeight: '14px',
                  }}
                >
                  Terms of Service
                </Button>
                <Button
                  variant="text"
                  onClick={() => {
                    closeAllMenus();
                    setPrivacyPolicyOpen(true);
                  }}
                  sx={{
                    fontSize: '10px',
                    lineHeight: '14px',
                  }}
                >
                  Privacy Policy
                </Button>
              </Stack>

              {HAS_DEBUG_OVERLAY && (
                <Button
                  onClick={() => dispatch(openDebugOverlay())}
                  sx={{
                    fontSize: '10px',
                    lineHeight: '14px',
                  }}
                >
                  Debug overlay
                </Button>
              )}
            </Stack>
          </Menu>

          <Menu
            open={languageOpen}
            onClose={() => setLanguageOpen(false)}
            anchorEl={subAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            MenuListProps={{ style: { padding: '4px' } }}
            elevation={1}
            sx={{ background: 'transparent', marginLeft: 1, p: 0.5 }}
          >
            <ListItem
              disablePadding
              sx={{
                marginBottom: '4px',
              }}
            >
              <MenuItem selected={!profile?.language} onClick={(event) => selectLanguage(event, '')}>
                <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>Dynamic</ListItemText>
                {!profile?.language && <CheckIcon />}
              </MenuItem>
            </ListItem>
            {space.languages.availableLanguages.map((language) => (
              <ListItem
                key={language}
                disablePadding
                sx={{
                  marginBottom: '4px',
                }}
              >
                <MenuItem selected={profile?.language === language} onClick={(event) => selectLanguage(event, language)}>
                  <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>{LANGUAGES[language]?.name}</ListItemText>
                  {profile?.language === language && <CheckIcon />}
                </MenuItem>
              </ListItem>
            ))}
          </Menu>

          <Menu
            open={appearanceOpen}
            onClose={() => setAppearanceOpen(false)}
            anchorEl={subAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            MenuListProps={{ style: { padding: '4px' } }}
            elevation={1}
            sx={{ background: 'transparent', marginLeft: 1, p: 0.5 }}
          >
            {themeSelector.availableThemes.map((theme) => (
              <ListItem
                key={theme.code}
                disablePadding
                sx={{
                  marginBottom: '4px',
                }}
              >
                <ListItemButton
                  sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    borderRadius: '12px',
                    p: 1,
                    color: 'text.secondary',
                    '&:hover': {
                      backgroundColor: 'transparent.hover',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'transparent.neutral.selected',
                      color: 'onSurface.highEmphasis',
                    },
                    gap: 1,
                    justifyContent: 'space-between',
                    minWidth: '150px',
                  }}
                  selected={themeSelector.theme === theme.code}
                  onClick={(event) => selectAppearance(event, theme.code)}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>{theme.name}</ListItemText>
                  {themeSelector.theme === theme.code && <CheckIcon />}
                </ListItemButton>
              </ListItem>
            ))}
          </Menu>
        </Box>
      </ClickAwayListener>
      <TermsOfServiceModal open={termsOfServiceOpen} close={() => setTermsOfServiceOpen(false)} />
      <PrivacyPolicyModal open={privacyPolicyOpen} close={() => setPrivacyPolicyOpen(false)} />
    </>
  );
}
