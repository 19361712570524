import { UiElementMapMarker } from '@ikon-web/ikon-client';
import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { calculateDistance } from '../../shared/utils/coordinate.utils';

export function Map(props: { marker: UiElementMapMarker; markers: UiElementMapMarker[] }) {
  const ref = useRef();

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center: { lat: props.marker.latitude, lng: props.marker.longitude },
      zoom: 15,
      mapId: '3721e58cc348208d',
    });

    const pinGlyph = new window.google.maps.marker.PinElement({
      background: '#3bb15e',
      borderColor: '#147e3a',
      glyphColor: '#147e3a',
    });
    new window.google.maps.marker.AdvancedMarkerElement({
      map,
      position: { lat: props.marker.latitude, lng: props.marker.longitude },
      title: props.marker.title,
      content: pinGlyph.element,
    });

    for (const marker of props.markers) {
      new window.google.maps.Marker({
        map,
        position: { lat: marker.latitude, lng: marker.longitude },
        title: `${marker.title}, ${Math.round(calculateDistance(props.marker.latitude, props.marker.longitude, marker.latitude, marker.longitude))}km`,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box sx={{ width: '100%', height: '300px' }} ref={ref}></Box>;
}
