export enum PluginApi {
  ClientAudioRecorder = 'client-audio-recorder',
  ClientAudioProcessor = 'client-audio-processor',
  ClientAudioRenderer = 'client-audio-renderer',
  ClientScreenRecorder = 'client-screen-recorder',
  ClientVideoRecorder = 'client-video-recorder',
  ClientVideoProcessor = 'client-video-processor',
  ClientVideoRenderer = 'client-video-renderer',
  ClientBlobRenderer = 'client-blob-renderer',
  ClientSceneRenderer = 'client-scene-renderer',
  Chat = 'chat',
  ChatInput = 'chat-input',
}
