export const color = {
  neutral: {
    50: '#F6F6F6',
    100: '#E7E7E7',
    200: '#D1D1D1',
    300: '#B0B0B0',
    400: '#888888',
    500: '#6D6D6D',
    600: '#5A5A5A',
    700: '#464646',
    800: '#333333',
    900: '#1F1F1F',
    950: '#151515',
  },
  primaryViolet: {
    50: '#EDF0FF',
    100: '#DFE2FF',
    200: '#C5CAFF',
    300: '#9196FF',
    400: '#817DFC',
    500: '#6E5EF6',
    600: '#5F41EA',
    700: '#5F41EA',
    800: '#442CA7',
    900: '#392B84',
    950: '#24194D',
  },
  transparent: {
    white: {
      100: 'rgba(255, 255, 255, 1)',
      90: 'rgba(255, 255, 255, 0.9)',
      60: 'rgba(255, 255, 255, 0.6)',
      40: 'rgba(255, 255, 255, 0.4)',
      30: 'rgba(255, 255, 255, 0.3)',
      25: 'rgba(255, 255, 255, 0.25)',
      20: 'rgba(255, 255, 255, 0.2)',
      12: 'rgba(255, 255, 255, 0.12)',
      10: 'rgba(255, 255, 255, 0.1)',
      8: 'rgba(255, 255, 255, 0.08)',
      5: 'rgba(255, 255, 255, 0.05)',
    },
    black: {
      100: 'rgba(0, 0, 0, 1)',
      90: 'rgba(0, 0, 0, 0.9)',
      60: 'rgba(0, 0, 0, 0.6)',
      40: 'rgba(0, 0, 0, 0.4)',
      30: 'rgba(0, 0, 0, 0.3)',
      25: 'rgba(0, 0, 0, 0.25)',
      20: 'rgba(0, 0, 0, 0.2)',
      12: 'rgba(0, 0, 0, 0.12)',
      10: 'rgba(0, 0, 0, 0.1)',
      8: 'rgba(0, 0, 0, 0.08)',
      5: 'rgba(0, 0, 0, 0.05)',
    },
    primary300: {
      12: 'rgba(145, 150, 255, 0.12)',
      10: 'rgba(145, 150, 255, 0.10)',
      8: 'rgba(145, 150, 255, 0.08)',
      5: 'rgba(145, 150, 255, 0.05)',
    },
    primary600: {
      12: 'rgba(95, 65, 234, 0.12)',
      10: 'rgba(95, 65, 234, 0.10)',
      8: 'rgba(95, 65, 234, 0.08)',
      5: 'rgba(95, 65, 234, 0.05)',
    },
  },
  purple: {
    50: '#FAF5FF',
    100: '#F3E9FE',
    200: '#E9D6FE',
    300: '#D7B6FC',
    400: '#BF88F8',
    500: '#A65AF2',
    600: '#9139E4',
    700: '#7C27C9',
    800: '#6925A4',
    900: '#571F84',
    950: '#3A0A61',
  },
  green: {
    50: '#F0FCE9',
    100: '#DEF8CF',
    200: '#ACEF8C',
    300: '#93E86E',
    400: '#6ED942',
    500: '#4EBF23',
    600: '#399818',
    700: '#2D7417',
    800: '#285C18',
    900: '#254E19',
    950: '#0E2B08',
  },
  red: {
    50: '#FFF1F2',
    100: '#FEE5E8',
    200: '#FDCED5',
    300: '#FAA7B3',
    400: '#F6667E',
    500: '#EF4466',
    600: '#DB234F',
    700: '#B91743',
    800: '#9B163E',
    900: '#85163A',
    950: '#4A071B',
  },
  blue: {
    50: '#EFF8FF',
    100: '#DAEEFF',
    200: '#BEE2FF',
    300: '#91D1FF',
    400: '#53B1FD',
    500: '#3795FA',
    600: '#2178EF',
    700: '#1960DC',
    800: '#1B4EB2',
    900: '#1C458C',
    950: '#162B55',
  },
  orange: {
    50: '#FEF6EE',
    100: '#FDEAD7',
    200: '#FAD2AE',
    300: '#F7B27A',
    400: '#F38744',
    500: '#EF6720',
    600: '#E14D15',
    700: '#BA3914',
    800: '#942F18',
    900: '#782816',
    950: '#40110A',
  },
};
