import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goNext, goPrevious, pushRoom, selectNextRoom, selectPreviousRoom } from '../data/room-stack.slice';

export function useRoomStack() {
  const dispatch = useDispatch();
  const nextRoom = useSelector(selectNextRoom);
  const previousRoom = useSelector(selectPreviousRoom);

  return useMemo(() => {
    return {
      nextRoom,
      previousRoom,
      goNext: () => dispatch(goNext()),
      goPrevious: () => dispatch(goPrevious()),
      pushRoom: (roomId: string) => dispatch(pushRoom({ roomId })),
    };
  }, [dispatch, nextRoom, previousRoom]);
}
