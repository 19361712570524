import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useAuth } from '../../shared/hooks/use-auth';
import { BottomMenu } from '../bottom-menu';
import { Notifications } from './notifications';
import { UserMenuButton } from './user-menu-button';
import { UserMenuInfo } from './user-menu-info';

export function UserMenuMobile() {
  const { user } = useAuth();

  return (
    <BottomMenu button={<UserMenuButton user={user} />}>
      <Stack direction="column" spacing={2} useFlexGap>
        <Box
          sx={{
            p: 0.5,
            borderRadius: 4,
            backgroundColor: 'surface.background.overlayTransparent',
          }}
        >
          <UserMenuInfo user={user} />
        </Box>
        <Box
          sx={{
            p: 0.5,
            borderRadius: 4,
            backgroundColor: 'surface.background.overlayTransparent',
          }}
        >
          <Notifications separator={true} />
        </Box>
      </Stack>
    </BottomMenu>
  );
}
