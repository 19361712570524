import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Box, Divider, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Fragment, useContext, useEffect } from 'react';
import { NotificationContext } from '../../shared/context/notification.context';
import { NotificationLine } from './notification-line';

export function Notifications(props: { separator?: boolean }) {
  const { notifications, hasNewNotifications, setNotificationsRead } = useContext(NotificationContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (hasNewNotifications) setNotificationsRead();
    }, 2000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasNewNotifications, notifications, setNotificationsRead]);

  return (
    <Stack direction="column" spacing={0.5} useFlexGap>
      <Box>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{
            px: 1,
            py: 1.5,
            color: 'text.secondary',
          }}
        >
          <NotificationsOutlinedIcon style={{ width: '20px', height: '20px' }} />
          <Typography variant="body2">Notifications</Typography>
        </Stack>
      </Box>

      {props.separator && <Divider />}

      {notifications.map((notification, index) => (
        <Fragment key={notification.id}>
          <NotificationLine notification={notification} />
          {props.separator && index < notifications.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Stack>
  );
}
