import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { TermsOfService } from './terms-of-service';

export function TermsOfServiceModal({ open, close }: { open: boolean; close: () => void }) {
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={close}>
      <DialogContent>
        <TermsOfService />
      </DialogContent>

      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
