import { IkonCommand } from '../ikon-command';
import { Plugin } from '../plugin';

export class UiPlugin implements Plugin {
  constructor(private ikonWorker: Worker) {
    console.debug('[UiPlugin] Initialise');

    this.ikonWorker.postMessage({ command: IkonCommand.InitializeUiReceiver });
  }

  async close() {
    console.debug('[UiPlugin] Close');
  }
}
