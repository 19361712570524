import { Box } from '@mui/material';
import { Layout1 } from './carousel/layout-1/layout-1';

export function UiContainerCarousel({ container, disabled }: { container: any; disabled?: boolean }) {
  return (
    <Box className="ikon-carousel">
      <Layout1 elements={container.elements} disabled={disabled} />
    </Box>
  );
}
