import { Notification } from '@ikon-web/notification-types';
import { createContext } from 'react';

export interface NotificationContextInterface {
  hasNewNotifications: boolean;
  notifications: Notification[];
  setNotificationsRead: () => void;
}

const stub = (): never => {
  throw new Error('Notification context provider missing');
};
export const initialContext = {
  hasNewNotifications: false,
  notifications: [],
  setNotificationsRead: stub,
};

export const NotificationContext = createContext<NotificationContextInterface>(initialContext);
