import { UiContainerOrElement } from '@ikon-web/ikon-client';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { UiElement } from '../../ui-element';

export function ElementCount3({ elements, disabled }: { elements: UiContainerOrElement[]; disabled?: boolean }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 2, height: '692px' }}>
        <Box sx={{ flex: '1 0 0', display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 2 }}>
          <Box sx={{ height: '260px' }}>
            <UiElement element={elements.at(0)} disabled={disabled} />
          </Box>
          <Box sx={{ height: '128px' }}>
            <UiElement element={elements.at(1)} disabled={disabled} />
          </Box>
        </Box>
        <Box sx={{ height: '272px' }}>
          <UiElement element={elements.at(2)} disabled={disabled} />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, height: '272px' }}>
      <Box sx={{ flex: 1, height: '100%' }}>
        <UiElement element={elements.at(0)} disabled={disabled} />
      </Box>
      <Box sx={{ flex: 1, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, height: '100%' }}>
          <Box sx={{ flex: 1, height: '100%' }}>
            <UiElement element={elements.at(1)} disabled={disabled} />
          </Box>
          <Box sx={{ width: '128px', height: '100%' }}>
            <UiElement element={elements.at(2)} disabled={disabled} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
