import { User } from '@ikon-web/user-types';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export function UserMenuInfo({ user }: { user: User }) {
  return (
    <Stack direction="column" spacing={1} useFlexGap>
      <Stack direction="row" spacing={1} useFlexGap sx={{ p: 1 }}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'stretch',
            padding: '10px',
            fontSize: '20px',
            lineHeight: '20px',
            backgroundColor: `primary.main`,
            borderRadius: '8px',
          }}
        >
          {user.name?.at(0) || 'U'}
        </Box>
        <Stack direction="column" spacing={0} useFlexGap>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Moi, {user.name}!
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {user.email}
          </Typography>
        </Stack>
      </Stack>
      <Box
        component={RouterLink}
        to="/auth/logout"
        sx={{
          px: 1,
          py: 1.5,
          borderRadius: '12px',
          transition: 'background 200ms ease',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: 'transparent.hover',
          },
        }}
      >
        <Stack direction="row" spacing={1} useFlexGap sx={{ color: 'text.secondary' }}>
          <LogoutIcon style={{ width: '20px', height: '20px' }} />
          <Typography variant="body2">Logout</Typography>
        </Stack>
      </Box>
    </Stack>
  );
}
