import * as Sentry from '@sentry/react';
import AudioRecorderPCMWorklet from '../../assets/audio-recorder-pcm-worklet.js?worker&url';
import AudioRecorderWorklet from '../../assets/audio-recorder-worklet.js?worker&url';
import { AUDIO_RECORDER_INTERVAL } from '../const';
import { IkonCommand } from '../ikon-command';
import { Plugin } from '../plugin';

export class AudioRecorderPlugin implements Plugin {
  private readonly hasAudioEncoder = typeof AudioEncoder !== 'undefined';
  private readonly audioContext = new AudioContext({ sampleRate: this.hasAudioEncoder ? 48000 : 16000 });
  private mediaStream?: MediaStream;
  private audioSource?: MediaStreamAudioSourceNode;
  private audioRecorderProcessor?: AudioWorkletNode;

  constructor(private ikonWorker: Worker) {
    console.debug('[AudioRecorderPlugin] Initialise');

    this.ikonWorker.addEventListener('message', (event: any) => {
      const command: IkonCommand = event.data.command;

      switch (command) {
        case IkonCommand.AudioRecorderSenderInitialized:
          this.audioRecorderProcessor?.port.postMessage(event.data);
          break;
      }
    });

    this.connect();
  }

  async close() {
    console.debug('[AudioRecorderPlugin] Close');
    this.audioRecorderProcessor?.disconnect();
    this.audioSource?.disconnect();
    this.mediaStream?.getTracks().forEach((track) => track.stop());
    await this.audioContext.close();
    this.ikonWorker.postMessage({ command: IkonCommand.CloseAudioRecorderSender });
  }

  pause() {
    setTimeout(() => {
      this.audioContext.suspend();
    }, AUDIO_RECORDER_INTERVAL * 4);
  }

  resume() {
    this.audioContext.resume();
  }

  private async connect() {
    await this.audioContext.suspend();

    if (!navigator.mediaDevices) {
      console.warn("[AudioRecorderPlugin] Media devices aren't supported");
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(async (stream) => {
        this.mediaStream = stream;
        this.audioSource = this.audioContext.createMediaStreamSource(stream);
        console.debug('[AudioRecorderPlugin] Audio source created');

        if (this.hasAudioEncoder) {
          console.debug('[AudioRecorderPlugin] Using recorder worklet');
          await this.audioContext.audioWorklet.addModule(AudioRecorderWorklet);
          this.audioRecorderProcessor = new AudioWorkletNode(this.audioContext, 'audio-recorder-worklet');
        } else {
          console.debug('[AudioRecorderPlugin] Using recorder pcm worklet');
          await this.audioContext.audioWorklet.addModule(AudioRecorderPCMWorklet);
          this.audioRecorderProcessor = new AudioWorkletNode(this.audioContext, 'audio-recorder-pcm-worklet', {
            processorOptions: {
              outputSampleRate: 16000,
            },
          });

          if (this.audioContext.sampleRate !== 16000) {
            console.warn(`[AudioRecorderPlugin] AudioContext sample rate is ${this.audioContext.sampleRate}Hz, resampling will be applied`);
          }
        }

        this.audioRecorderProcessor.port.onmessage = (event: any) => {
          if (event.data.command === IkonCommand.AudioRecorderWorkletInitialized) {
            this.ikonWorker.postMessage(event.data);
          }
        };
        this.audioSource.connect(this.audioRecorderProcessor);
        console.debug('[AudioRecorderPlugin] Audio recorder connected');
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error('[AudioRecorderPlugin] Error connecting media devices', err);
      });
  }
}
