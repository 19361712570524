import { Box, Divider, Menu } from '@mui/material';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useState } from 'react';
import { useAuth } from '../../shared/hooks/use-auth';
import { Notifications } from './notifications';
import { UserMenuButton } from './user-menu-button';
import { UserMenuInfo } from './user-menu-info';

export function UserMenuDesktop() {
  const { user } = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    handleToggle();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <UserMenuButton user={user} handleOpen={handleOpen} />

      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        MenuListProps={{ style: { padding: 0 } }}
        elevation={1}
        sx={{ background: 'transparent', marginTop: 1 }}
      >
        <Stack
          direction="column"
          spacing={0.5}
          useFlexGap
          sx={{
            p: 0.5,
          }}
        >
          <UserMenuInfo user={user} />
          <Divider />
          <Notifications />
        </Stack>
      </Menu>
    </Box>
  );
}
