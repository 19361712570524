import { Box, useMediaQuery, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Outlet } from 'react-router-dom';
import { useSpace } from '../../shared/hooks/use-space';

export function AuthPage() {
  const space = useSpace();
  const logoUrl = space?.assets.find((a) => a.type === 'space-login-image')?.url || '/login-bg.jpg';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Stack direction="column" sx={{ height: '100dvh' }}>
        <Box component="img" src={logoUrl} sx={{ height: '200px' }} />
        <Box
          sx={{
            height: '100%',
            p: 3,
            pt: 4,
            marginTop: '-16px',
            borderStartStartRadius: '16px',
            borderStartEndRadius: '16px',
            backgroundColor: 'background.default',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Outlet />
        </Box>
      </Stack>
    );
  }

  return (
    <Stack direction="row" sx={{ height: '100dvh' }}>
      <Box
        sx={{
          display: 'flex',
          padding: '40px',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flex: '1 0 0',
          alignSelf: 'stretch',
        }}
      >
        <Outlet />
      </Box>
      <Box
        sx={{
          padding: '40px',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flex: '1 0 0',
          alignSelf: 'stretch',
        }}
      >
        <Box component="img" src={logoUrl} sx={{ maxHeight: '100%', maxWidth: 'calc(100vw / 2 - 24px)', borderRadius: '24px' }} />
      </Box>
    </Stack>
  );
}
