import { UIElementLabels } from '@ikon-web/event-shared';
import { UiElementInputText } from '@ikon-web/ikon-client';
import { TextField } from '@mui/material';
import { useIkon } from '../../shared/hooks/use-ikon';

export function UiInputText({ element, disabled }: { element: UiElementInputText; disabled?: boolean }) {
  const ikon = useIkon();

  const isDisabled = disabled || element.labels?.includes(UIElementLabels.Disabled);

  return (
    <TextField
      type="text"
      fullWidth
      size={element.labels.includes(UIElementLabels.SizeSmall) ? 'small' : undefined}
      label={element.name}
      disabled={isDisabled}
      onChange={(event) => ikon.sendTextAction(element.actionId, event.target.value)}
    />
  );
}
