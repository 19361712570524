import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

const initialState: {
  loaded: boolean;
  host?: string;
  id?: string;
  theme?: string;
} = { loaded: false };

const interactionSlice = createSlice({
  name: 'embeddedSpace',
  initialState,
  reducers: {
    setEmbeddedSpace: (state, { payload: { id, theme, host } }: PayloadAction<{ id: string; theme?: string; host?: string }>) => {
      state.loaded = true;
      state.id = id;
      state.theme = theme;
      state.host = host;
    },
    reset: () => initialState,
  },
});

export const embeddedSpaceReducer = interactionSlice.reducer;

export const { setEmbeddedSpace, reset: resetEmbeddedSpace } = interactionSlice.actions;

export const selectEmbeddedSpaceState = (state: RootState) => state.embeddedSpace;
export const selectEmbeddedSpace = createSelector(selectEmbeddedSpaceState, (state) => state.id);
export const selectEmbeddedSpaceTheme = createSelector(selectEmbeddedSpaceState, (state) => state.theme);
