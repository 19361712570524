export function DeployedCodeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_4857_2022" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4857_2022)">
        <path
          d="M11.0963 21.2058L4.404 17.3538C4.11933 17.1898 3.8975 16.9707 3.7385 16.6963C3.5795 16.4218 3.5 16.1205 3.5 15.7923V8.20785C3.5 7.87968 3.5795 7.57835 3.7385 7.30385C3.8975 7.02951 4.11933 6.81035 4.404 6.64635L11.0963 2.79435C11.3808 2.63018 11.682 2.5481 12 2.5481C12.318 2.5481 12.6193 2.63018 12.9038 2.79435L19.596 6.64635C19.8807 6.81035 20.1025 7.02951 20.2615 7.30385C20.4205 7.57835 20.5 7.87968 20.5 8.20785V15.7923C20.5 16.1205 20.4205 16.4218 20.2615 16.6963C20.1025 16.9707 19.8807 17.1898 19.596 17.3538L12.9038 21.2058C12.6193 21.37 12.318 21.4521 12 21.4521C11.682 21.4521 11.3808 21.37 11.0963 21.2058ZM11.25 12.4308V19.5693L11.8652 19.9231C11.9102 19.9488 11.9552 19.9616 12 19.9616C12.0448 19.9616 12.0898 19.9488 12.1348 19.9231L12.75 19.5693V12.4308L19 8.8116V8.1656C19 8.13343 18.992 8.10135 18.976 8.06935C18.96 8.03735 18.9359 8.01168 18.9038 7.99235L18.175 7.56935L12 11.1386L5.825 7.56935L5.09625 7.99235C5.06408 8.01168 5.04 8.03735 5.024 8.06935C5.008 8.10135 5 8.13343 5 8.1656V8.8116L11.25 12.4308Z"
          fill="white"
          fillOpacity="0.6"
        />
      </g>
    </svg>
  );
}
