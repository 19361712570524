import { ListItemButton, ListItemButtonProps } from '@mui/material';
import { styled } from '@mui/system';
import { LinkProps } from 'react-router-dom';

export const MenuItem = styled(ListItemButton)<ListItemButtonProps & Partial<LinkProps>>(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  borderRadius: '12px',
  padding: '12px 16px',
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: theme.palette.transparent.hover,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.transparent.primary.selected,
    color: theme.palette.primary.main,
  },
  gap: theme.spacing(1),
  // justifyContent: 'space-between',
  // minWidth: '150px',
}));
