import { UiContainerOrElement } from '@ikon-web/ikon-client';
import { ElementCount2 } from './element-count-2';
import { ElementCount3 } from './element-count-3';
import { ElementCount4 } from './element-count-4';
import { ElementCount5 } from './element-count-5';
import { ElementCount6 } from './element-count-6';
import { ElementCount7 } from './element-count-7';
import { ElementCount8 } from './element-count-8';

export function Layout1({ elements, disabled }: { elements: UiContainerOrElement[]; disabled?: boolean }) {
  switch (elements.length) {
    case 2:
      return <ElementCount2 elements={elements} disabled={disabled} />;
    case 3:
      return <ElementCount3 elements={elements} disabled={disabled} />;
    case 4:
      return <ElementCount4 elements={elements} disabled={disabled} />;
    case 5:
      return <ElementCount5 elements={elements} disabled={disabled} />;
    case 6:
      return <ElementCount6 elements={elements} disabled={disabled} />;
    case 7:
      return <ElementCount7 elements={elements} disabled={disabled} />;
    case 8:
      return <ElementCount8 elements={elements} disabled={disabled} />;
  }

  return null;
}
