import { createContext } from 'react';

export interface ThemeSelectorContextInterface {
  theme: string;
  selectTheme: (theme: string) => void;
  availableThemes: { code: string; name: string }[];
}

const stub = (): never => {
  throw new Error('Theme selector context provider missing');
};

export const initialContext = {
  theme: 'dark',
  selectTheme: stub,
  availableThemes: [],
};

export const ThemeSelectorContext = createContext<ThemeSelectorContextInterface>(initialContext);
