import { UiElementVegaChart } from '@ikon-web/ikon-client';
import { VegaLite } from 'react-vega';

export function UiVegaChart({ element: { spec, data } }: { element: UiElementVegaChart }) {
  if (!spec || !data) return null;

  const mutableSpec = JSON.parse(JSON.stringify(spec));
  const mutableData = JSON.parse(JSON.stringify(data));

  return <VegaLite spec={mutableSpec} data={mutableData} />;
}
