import { LANGUAGES } from '@ikon-web/utils';
import CheckIcon from '@mui/icons-material/Check';
import { List, ListItem, ListItemText } from '@mui/material';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { BottomMenu } from '../../../../components/bottom-menu';
import { MenuItem } from '../../../../components/menu/menu-item';
import { api } from '../../../../shared/api/api';
import { useSpace } from '../../../../shared/hooks/use-space';
import { selectCurrentProfile } from '../../../profile/current-profile.slice';
import { SettingsLanguageMenuButton } from './settings-language-menu-button';

export function SettingsLanguageMobile() {
  const space = useSpace();
  const profile = useSelector(selectCurrentProfile);

  const [updateProfile] = api.useUpdateProfileMutation();

  const selectLanguage = async (event: React.MouseEvent<HTMLDivElement>, language: string) => {
    event.stopPropagation();
    await updateProfile({ id: profile.id, form: { language } }).unwrap();
  };

  return (
    <BottomMenu button={<SettingsLanguageMenuButton />}>
      <Stack direction="column" spacing={0.5} useFlexGap>
        <List>
          <ListItem
            disablePadding
            sx={{
              marginBottom: '4px',
            }}
          >
            <MenuItem selected={!profile?.language} onClick={(event) => selectLanguage(event, '')}>
              <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>Dynamic</ListItemText>
              {!profile?.language && <CheckIcon />}
            </MenuItem>
          </ListItem>

          {space.languages.availableLanguages.map((language) => (
            <ListItem
              key={language}
              disablePadding
              sx={{
                marginBottom: '4px',
              }}
            >
              <MenuItem selected={profile?.language === language} onClick={(event) => selectLanguage(event, language)}>
                <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>{LANGUAGES[language]?.name}</ListItemText>
                {profile?.language === language && <CheckIcon />}
              </MenuItem>
            </ListItem>
          ))}
        </List>
      </Stack>
    </BottomMenu>
  );
}
