import { Room, SpacePublic } from '@ikon-web/space-types';
import { Box, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useContext, useMemo } from 'react';
import errorDark from '../../assets/error_dark.png';
import errorDarkMobile from '../../assets/error_dark_mobile.png';
import errorLight from '../../assets/error_light.png';
import errorLightMobile from '../../assets/error_light_mobile.png';
import { ThemeSelectorContext } from '../../shared/context/theme-selector.context';

export function RoomError({ space, room, roomError }: { space: SpacePublic; room: Room; roomError: { error?: Error; validateUrl?: string } }) {
  const themeSelector = useContext(ThemeSelectorContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const background = useMemo(() => {
    if (themeSelector.theme === 'dark') {
      return `url(${isMobile ? errorDarkMobile : errorDark})`;
    }
    return `url(${isMobile ? errorLightMobile : errorLight})`;
  }, [themeSelector.theme, isMobile]);

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            maxHeight: '400px',
            height: '400px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            background: background,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            zIndex: -1,
          }}
        ></Box>

        <Stack sx={{ maxWidth: '800px' }}>
          <Box sx={{ p: { xs: '16px', md: '0px 240px 0px 32px' } }}>
            <Typography component="h1" variant="h3" sx={{ mb: '33px' }}>
              Whoops!
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '20px' }}>
              Something went wrong, but don’t worry—our team is on it. Try again later or contact our support team.
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '20px' }}>
              <Link href="mailto:support@ikon.live" sx={{ textDecoration: 'none' }}>
                support@ikon.live
              </Link>
            </Typography>

            {roomError?.validateUrl && (
              <Typography variant="body1" sx={{ mt: 5 }}>
                <Link href={roomError.validateUrl} target="_blank">
                  Validate certificate
                </Link>
              </Typography>
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
