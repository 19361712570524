import { IkonCommand } from '../ikon-command';
import { Plugin } from '../plugin';

export class VideoPlayerPlugin implements Plugin {
  constructor(
    private ikonWorker: Worker,
    canvas: HTMLCanvasElement,
  ) {
    console.debug('[VideoPlayerPlugin] Initialise');

    const offscreenCanvas = canvas.transferControlToOffscreen();
    ikonWorker.postMessage(
      {
        command: IkonCommand.InitializeVideoRenderer,
        canvas: offscreenCanvas,
      },
      { transfer: [offscreenCanvas] },
    );
  }

  async close() {
    console.debug('[VideoPlayerPlugin] Close');
    this.ikonWorker.postMessage({ command: IkonCommand.CloseVideoRenderer });
  }
}
