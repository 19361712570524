import { User } from '@ikon-web/user-types';
import { createContext } from 'react';

export interface AuthContextInterface {
  isAuthenticated: boolean;
  isAnonymous: boolean;
  isLoading: boolean;
  user?: User;
  error?: Error;
  loginProvider: (provider: string) => Promise<void>;
  loginUsername: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  loadAuthentication: () => Promise<void>;
}

const stub = (): never => {
  throw new Error('Auth context provider missing');
};
export const initialContext = {
  isAuthenticated: false,
  isAnonymous: false,
  isLoading: true,
  user: undefined,
  loginProvider: stub,
  loginUsername: stub,
  logout: stub,
  loadAuthentication: stub,
};

export const AuthContext = createContext<AuthContextInterface>(initialContext);
