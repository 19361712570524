import { LOCAL_STORAGE_AUTH_DEVICE_ID, LOCAL_STORAGE_AUTH_USER } from '../constants';

export function getDeviceId(userId?: string): string {
  if (!userId) return '';

  let deviceId: string;
  if (localStorage.getItem(LOCAL_STORAGE_AUTH_USER) === userId) {
    deviceId = getOrSetDeviceId();
  } else {
    localStorage.setItem(LOCAL_STORAGE_AUTH_USER, userId);
    deviceId = setDeviceId();
  }

  return deviceId;
}

function getOrSetDeviceId() {
  let deviceId = localStorage.getItem(LOCAL_STORAGE_AUTH_DEVICE_ID);
  if (!deviceId) {
    deviceId = setDeviceId();
  }

  return deviceId;
}

function setDeviceId() {
  // TODO: Fix this fallback
  const deviceId = crypto.randomUUID ? crypto.randomUUID() : 'local';
  localStorage.setItem(LOCAL_STORAGE_AUTH_DEVICE_ID, deviceId);
  return deviceId;
}
