import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { MouseEvent, useContext } from 'react';
import { ThemeSelectorContext } from '../../../../shared/context/theme-selector.context';

export function SettingsThemeMenuButton(props: { handleOpen?: (event: MouseEvent<HTMLDivElement>) => void }) {
  const themeSelector = useContext(ThemeSelectorContext);

  return (
    <Stack
      direction="row"
      spacing={1}
      useFlexGap
      sx={{
        p: 1,
        color: 'text.secondary',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'transparent.hover',
        },
      }}
      onClick={props.handleOpen}
    >
      <DarkModeOutlinedIcon />
      <Typography variant="body2" sx={{ flex: 1 }}>
        Appearance
      </Typography>
      <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
        {themeSelector.theme}
      </Typography>
      <ChevronRightIcon />
    </Stack>
  );
}
