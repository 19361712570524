import { Box, SxProps } from '@mui/material';
import Markdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import remarkGfm from 'remark-gfm';

export function UiTextMarkdown({ text, sx }: { text: string; sx: SxProps }) {
  return (
    <Box
      sx={{
        ...sx,
        '& a': { color: 'rgba(145, 150, 255, 1)', textDecoration: 'none' },
        '& > *:not(:last-child)': { mb: '.5rem' },
        '& h1': { m: '0' },
        '& h2': { m: '0' },
        '& h3': { m: '0' },
        '& p': { m: 0 },
        '& ul': { m: '0' },
        '& table': {
          borderSpacing: 0,
          borderCollapse: 'collapse',
          display: 'block',
          marginTop: 0,
          marginBottom: '16px',
          width: 'max-content',
          maxWidth: '100%',
          overflow: 'auto',
        },
        '& tr': {
          backgroundColor: '#fff',
          borderTop: '1px solid hsla(210, 18%, 87%, 1)',
          color: '#000',
        },
        '& tr:nth-of-type(even)': {
          backgroundColor: '#f6f8fa',
          color: '#000',
        },
        '& td': {
          padding: '6px 13px',
          border: '1px solid #d0d7de',
        },
        '& th': {
          padding: '6px 13px',
          border: '1px solid #d0d7de',
          fontWeight: 600,
        },
        '& table img': {
          backgroundColor: 'transparent',
        },
        '& pre': {
          whiteSpace: 'pre-wrap',
        },
      }}
    >
      <Markdown rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]} remarkPlugins={[remarkGfm]}>
        {text}
      </Markdown>
    </Box>
  );
}
