import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../shared/data/store';

const initialState: { open: boolean } = { open: false };

const debugOverlaySlice = createSlice({
  name: 'debugOverlay',
  initialState,
  reducers: {
    open: (state) => {
      state.open = true;
    },
    close: (state) => {
      state.open = false;
    },
    reset: () => initialState,
  },
});

export const debugOverlayReducer = debugOverlaySlice.reducer;

export const { open: openDebugOverlay, close: closeDebugOverlay, reset: resetDebugOverlay } = debugOverlaySlice.actions;

export const selectDebugOverlayOpen = (state: RootState) => state.debugOverlay.open;
