import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

const initialState: {
  hasInteraction: boolean;
} = {
  hasInteraction: false,
};

const interactionSlice = createSlice({
  name: 'interaction',
  initialState,
  reducers: {
    setInteraction: (state) => {
      state.hasInteraction = true;
    },
    reset: () => initialState,
  },
});

export const interactionReducer = interactionSlice.reducer;

export const { setInteraction: setHasInteraction, reset: resetInteraction } = interactionSlice.actions;

export const selectHasInteraction = (state: RootState) => state.interaction.hasInteraction;
