import { UIElementLabels } from '@ikon-web/event-shared';
import { UiAutocomplete } from '@ikon-web/ikon-client';
import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import { useIkon } from '../../shared/hooks/use-ikon';
import { UiColorToString, UiInputVariantToString } from '../../shared/utils/protocol.utils';

export function UiAutocompleteInput({ element, disabled }: { element: UiAutocomplete; disabled?: boolean }) {
  const ikon = useIkon();
  const [value, setValue] = useState<{ label: string; id: string }[]>([]);

  const isDisabled = disabled || element.labels?.includes(UIElementLabels.Disabled);

  return (
    <Autocomplete
      multiple={element.maxCount > 1}
      options={element.options}
      disabled={isDisabled}
      renderInput={(params) => <TextField {...params} color={UiColorToString(element.color)} variant={UiInputVariantToString(element.variant)} label={element.name} placeholder="Select value" />}
      onChange={(event, value) => {
        value = value ? (Array.isArray(value) ? value : [value]) : [];
        setValue(value);
        ikon.sendTextAction(element.actionId, JSON.stringify(value?.map((v) => v.id) ?? []));
      }}
      getOptionDisabled={() => (element.maxCount > 0 ? value.length >= element.maxCount : false)}
    />
  );
}
