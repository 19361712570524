import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { ReactElement, ReactNode } from 'react';

const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

export function MapLoader(props: { children?: ReactNode }) {
  function render(status: Status): ReactElement {
    if (status === Status.FAILURE) return <div>Map error</div>;
    return <div>Loadings</div>;
  }

  return (
    <Wrapper apiKey={API_KEY} libraries={['marker']} render={render}>
      {props.children}
    </Wrapper>
  );
}
