import { Box, useTheme } from '@mui/material';

export function AiLogo() {
  const theme = useTheme();

  return (
    <div className="ai-logo">
      <svg viewBox="-100 -100 200 200 " xmlns="http://www.w3.org/2000/svg" className="blob blob1">
        <Box
          component="path"
          d="M50.2,-77.3C64.6,-68.8,75.7,-54.2,83.2,-38C90.7,-21.7,94.7,-3.8,92.7,13.7C90.7,31.1,82.9,48.1,70.1,58.2C57.3,68.3,39.6,71.5,22.8,75.4C6,79.3,-10,83.8,-25.1,81.3C-40.3,78.8,-54.6,69.3,-62.8,56.4C-70.9,43.4,-72.7,27.1,-73,11.7C-73.3,-3.6,-71.9,-17.9,-68.2,-33.2C-64.4,-48.5,-58.2,-64.7,-46.4,-74.4C-34.7,-84.2,-17.3,-87.4,0.3,-87.8C17.9,-88.2,35.7,-85.8,50.2,-77.3Z"
          fill={theme.palette.transparent.primary.focus}
        />
      </svg>
      <svg viewBox="-100 -100 200 200 " xmlns="http://www.w3.org/2000/svg" className="blob blob2">
        <Box
          component="path"
          d="M40.6,-66.8C50.3,-56.9,54.4,-41.8,58.1,-28.2C61.8,-14.5,65,-2.4,65.9,11.3C66.9,25,65.4,40.2,58.3,53.2C51.2,66.1,38.4,76.8,23.9,81C9.3,85.1,-7.2,82.7,-21.6,77C-36.1,71.2,-48.5,62,-59.5,50.8C-70.4,39.5,-79.8,26.2,-83.8,10.9C-87.9,-4.4,-86.6,-21.7,-78.3,-34.1C-70,-46.4,-54.8,-53.9,-40.5,-61.9C-26.3,-69.8,-13.2,-78.2,1.1,-79.9C15.4,-81.7,30.8,-76.8,40.6,-66.8Z"
          fill={theme.palette.primary.main}
        />
      </svg>
      <svg viewBox="-100 -100 200 200 " xmlns="http://www.w3.org/2000/svg" className="blob blob3">
        <Box
          component="path"
          d="M44.7,-77.5C57.2,-70.3,65.9,-56.7,71.4,-42.7C76.9,-28.7,79.2,-14.4,79.2,0C79.2,14.3,76.9,28.7,69.8,40C62.8,51.3,51,59.6,38.6,65.1C26.1,70.5,13.1,73.2,-1.3,75.4C-15.7,77.7,-31.3,79.6,-41.5,72.8C-51.8,66.1,-56.5,50.7,-61.5,37.1C-66.6,23.6,-72,11.8,-74.8,-1.6C-77.7,-15.1,-78,-30.1,-72,-42C-65.9,-53.9,-53.5,-62.5,-40.4,-69.4C-27.4,-76.2,-13.7,-81.2,1.2,-83.3C16.1,-85.4,32.3,-84.6,44.7,-77.5Z"
          fill="rgba(14, 179, 204, 0.4)"
        />
      </svg>
      <svg viewBox="-100 -100 200 200 " xmlns="http://www.w3.org/2000/svg" className="blob blob4">
        <Box
          component="path"
          d="M49.1,-60.3C62.4,-47.4,71.1,-30.7,75.9,-12.1C80.7,6.5,81.6,27.1,73.2,42.7C64.8,58.4,46.9,69,28.6,73.5C10.3,78,-8.4,76.2,-26.7,70.7C-45,65.2,-63,56,-71.7,41.2C-80.5,26.4,-80.1,6.2,-75,-11.6C-69.9,-29.4,-60.2,-44.7,-46.9,-57.6C-33.6,-70.6,-16.8,-81.1,0.6,-81.7C17.9,-82.4,35.9,-73.3,49.1,-60.3Z"
          fill={theme.palette.background.default}
        />
      </svg>
    </div>
  );
}
