import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setEmbeddedSpace } from '../data/embedded-space.slice';

export function useEmbeddedSpace() {
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const embeddedSpaceId = params.get('embedded-space-id');
    const theme = params.get('embedded-space-theme');
    const host = params.get('host');
    if (embeddedSpaceId) {
      console.log(`[Meet] Running in embedded space: ${embeddedSpaceId}`);
    }

    dispatch(setEmbeddedSpace({ id: embeddedSpaceId, theme, host }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
