import { Box } from '@mui/material';
import { TermsOfService } from '../../components/terms-of-service';

export function TermsOfServicePage() {
  return (
    <Box sx={{ p: 4, height: '100dvh', overflow: 'auto' }}>
      <TermsOfService />
    </Box>
  );
}
