import { IkonCommand } from '../ikon-command';

export class ScenePlayer {
  constructor(ikonWorker: Worker, canvas: HTMLCanvasElement, bucketViewUrl?: string) {
    console.log('[ScenePlayer] Initialise');

    const offscreenCanvas = canvas.transferControlToOffscreen();

    ikonWorker.postMessage(
      {
        command: IkonCommand.InitializeScenePlayer,
        canvas: offscreenCanvas,
        bucketViewUrl,
      },
      { transfer: [offscreenCanvas] },
    );
  }
}
