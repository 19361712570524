import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import runsOnIkonMind from '../../assets/runs-on-ikon-mind.svg';
import { useAuth } from '../../shared/hooks/use-auth';
import { useSpace } from '../../shared/hooks/use-space';

export function LogoutPage() {
  const { logout } = useAuth();
  const space = useSpace();

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ maxWidth: '320px', ml: 'auto', mr: 'auto' }}>
      <Box component="img" src={runsOnIkonMind} sx={{ height: '14px' }} />

      <Typography variant="h1" sx={{ mt: 7, fontSize: '24px', fontWeight: '400', letterSpacing: '0.18px' }}>
        Logging out <strong>{space?.customisation?.name ?? 'Ikon'}</strong>
      </Typography>

      <Typography variant="body1" sx={{ mt: 6, textAlign: 'center' }}>
        <CircularProgress />
      </Typography>
    </Box>
  );
}
