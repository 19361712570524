import { UIColor, UIElementLabels } from '@ikon-web/event-shared';
import { UiElementMaterialSymbol } from '@ikon-web/ikon-client';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { UiColorToString } from '../../shared/utils/protocol.utils';

export function UiMaterialSymbol({ element, disabled }: { element: UiElementMaterialSymbol; disabled?: boolean }) {
  const size = element.labels.includes(UIElementLabels.SizeMedium) ? '48px' : element.labels.includes(UIElementLabels.SizeSmall) ? '14px' : '24px';

  const color = useMemo(() => {
    if (disabled) return 'text.disabled';

    if (element.color === UIColor.Secondary) return 'text.secondary';
    const color = UiColorToString(element.color);
    if (!color) return null;

    return `${color}.main`;
  }, [element.color, disabled]);

  return (
    <Box component="span" className="material-symbols-rounded" sx={{ fontSize: size, color }}>
      {element.name}
    </Box>
  );
}
