import * as Sentry from '@sentry/react';
import AudioPlayerWorklet from '../../assets/audio-player-worklet.js?worker&url';
import { IkonCommand } from '../ikon-command';
import { Plugin } from '../plugin';

export class AudioPlayerPlugin implements Plugin {
  private readonly audioContext: AudioContext;
  private audioWorkletNode?: AudioWorkletNode;
  private sampleRate = 48000;
  private channels = 1;
  private minBufferSizeSeconds = 0.25;
  private maxBufferSizeSeconds = 2.0;

  constructor(private ikonWorker: Worker) {
    console.debug('[AudioPlayerPlugin] Initialise');

    this.audioContext = new AudioContext({
      sampleRate: this.sampleRate,
      latencyHint: 'interactive',
    });

    this.ikonWorker.addEventListener('message', (event) => {
      const command = event.data.command;

      if (command === IkonCommand.AudioPlayerReceiverInitialized) {
        this.audioWorkletNode?.port.postMessage(event.data);
        this.audioContext?.resume();
      }

      if (command === IkonCommand.AudioPlayerReceiverRemoved) {
        this.audioWorkletNode?.port.postMessage(event.data);
      }
    });

    this.initialize();
  }

  async close() {
    console.debug('[AudioPlayerPlugin] Close');
    await this.audioContext.close();
    this.ikonWorker.postMessage({ command: IkonCommand.CloseAudioPlayerReceiver });
  }

  pause() {
    this.audioContext?.suspend();
  }

  resume() {
    this.audioContext?.resume();
  }

  private async initialize() {
    try {
      await this.audioContext.audioWorklet.addModule(AudioPlayerWorklet);
      this.audioWorkletNode = new AudioWorkletNode(this.audioContext, 'audio-player-worklet', {
        processorOptions: {
          outputAudioConfig: {
            sampleRate: this.sampleRate,
            channels: this.channels,
            minBufferSizeSeconds: this.minBufferSizeSeconds,
            maxBufferSizeSeconds: this.maxBufferSizeSeconds,
          },
        },
      });

      this.audioWorkletNode.port.onmessage = (event: any) => {
        const command = event.data.command;

        if (command === IkonCommand.AudioPlayerWorkletInitialized) {
          this.ikonWorker.postMessage(event.data);
        }
      };

      this.audioWorkletNode.connect(this.audioContext.destination);

      this.ikonWorker.postMessage({
        command: IkonCommand.InitializeAudioPlayerReceiver,
        outputAudioConfig: {
          sampleRate: this.sampleRate,
          channels: this.channels,
          minBufferSizeSeconds: this.minBufferSizeSeconds,
          maxBufferSizeSeconds: this.maxBufferSizeSeconds,
        },
      });
    } catch (err) {
      Sentry.captureException(err);
      console.error('[AudioPlayerPlugin] Failed to initialise', err);
    }
  }
}
