import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

const initialState: {
  sidebarOpen: boolean;
} = {
  sidebarOpen: false,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarOpen: (state, { payload: { sidebarOpen } }: PayloadAction<{ sidebarOpen: boolean }>) => {
      state.sidebarOpen = sidebarOpen;
    },
    reset: () => initialState,
  },
});

export const sidebarReducer = sidebarSlice.reducer;

export const { setSidebarOpen, reset: resetSidebar } = sidebarSlice.actions;

export const selectSidebarOpen = (state: RootState) => state.sidebar.sidebarOpen;
