import { useSelector } from 'react-redux';
import { selectContainerById } from '../../shared/data/container.slice';
import { RootState } from '../../shared/data/store';
import { UiContainerElements } from './ui-container-elements';

export function UiContainer({ containerId, myMessage, disabled }: { containerId: string; myMessage?: boolean; disabled?: boolean }) {
  const container = useSelector((state: RootState) => selectContainerById(state, containerId));

  if (!container) return null;
  if (container.elements.length === 0) return null;

  return <UiContainerElements container={container} myMessage={myMessage} disabled={disabled} />;
}
