import { LANGUAGES } from '@ikon-web/utils';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LanguageIcon from '@mui/icons-material/Language';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentProfile } from '../../../profile/current-profile.slice';

export function SettingsLanguageMenuButton(props: { handleOpen?: (event: MouseEvent<HTMLDivElement>) => void }) {
  const profile = useSelector(selectCurrentProfile);

  return (
    <Stack
      direction="row"
      spacing={1}
      useFlexGap
      sx={{
        p: 1,
        color: 'text.secondary',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'transparent.hover',
        },
      }}
      onClick={props.handleOpen}
    >
      <LanguageIcon />
      <Typography variant="body2" sx={{ flex: 1 }}>
        Language
      </Typography>
      <Typography variant="caption">{profile?.language ? LANGUAGES[profile.language]?.name || profile.language : 'Dynamic'}</Typography>
      <ChevronRightIcon />
    </Stack>
  );
}
