import { UiContainerOrElement } from '@ikon-web/ikon-client';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { UiElement } from '../../ui-element';

export function ElementCount7({ elements, disabled }: { elements: UiContainerOrElement[]; disabled?: boolean }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 2, height: '692px' }}>
        <Box sx={{ flex: '1 0 0', display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 2 }}>
          <Box sx={{ flex: '1 0 0', display: 'flex', alignItems: 'stretch', gap: 2 }}>
            <Box sx={{ width: '128px', height: '128px' }}>
              <UiElement element={elements.at(0)} disabled={disabled} />
            </Box>
            <Box sx={{ flex: '1 0 0', height: '128px' }}>
              <UiElement element={elements.at(1)} disabled={disabled} />
            </Box>
          </Box>
          <Box sx={{ height: '128px' }}>
            <UiElement element={elements.at(2)} disabled={disabled} />
          </Box>
          <Box sx={{ flex: '1 0 0', display: 'flex', alignItems: 'stretch', gap: 2 }}>
            <Box sx={{ flex: '1 0 0', height: '128px' }}>
              <UiElement element={elements.at(3)} disabled={disabled} />
            </Box>
            <Box sx={{ width: '128px', height: '128px' }}>
              <UiElement element={elements.at(4)} disabled={disabled} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ flex: '1 0 0', display: 'flex', alignItems: 'stretch', gap: 2 }}>
          <Box sx={{ flex: '1 0 0', height: '272px' }}>
            <UiElement element={elements.at(4)} disabled={disabled} />
          </Box>
          <Box sx={{ flex: '1 0 0', height: '272px' }}>
            <UiElement element={elements.at(5)} disabled={disabled} />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, height: '272px' }}>
      <Box sx={{ flex: '1 0 0', height: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'stretch', gap: 2 }}>
          <Box sx={{ width: '128px', height: '128px' }}>
            <UiElement element={elements.at(0)} disabled={disabled} />
          </Box>
          <Box sx={{ flex: '1 0 0', height: '128px' }}>
            <UiElement element={elements.at(1)} disabled={disabled} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'stretch', gap: 2 }}>
          <Box sx={{ flex: '1 0 0', height: '128px' }}>
            <UiElement element={elements.at(2)} disabled={disabled} />
          </Box>
          <Box sx={{ width: '128px', height: '128px' }}>
            <UiElement element={elements.at(3)} disabled={disabled} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: '1 0 0', alignItems: 'flex-start', height: '100%', display: 'flex', gap: 2 }}>
        <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 2 }}>
          <Box sx={{ flex: '1 0 0', height: '128px', minHeight: '128px' }}>
            <UiElement element={elements.at(4)} disabled={disabled} />
          </Box>
          <Box sx={{ flex: '1 0 0', height: '128px', minHeight: '128px' }}>
            <UiElement element={elements.at(5)} disabled={disabled} />
          </Box>
        </Box>
        <Box sx={{ flex: '1 0 auto', width: '128px', height: '100%' }}>
          <UiElement element={elements.at(6)} disabled={disabled} />
        </Box>
      </Box>
    </Box>
  );
}
