import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { api } from '../../shared/api/api';
import { useSpace } from '../../shared/hooks/use-space';
import { Overlay } from '../overlay/overlay';
import { setProfile } from '../profile/current-profile.slice';
import { setSpace } from './space.slice';

export function SpaceProvider() {
  const dispatch = useDispatch();
  const space = useSpace();
  const { data: profileResponse, isLoading: profileLoading, error: profileError } = api.useGetProfileByMeQuery({ space: space?.id }, { skip: !space });

  useEffect(() => {
    if (space) dispatch(setSpace({ space }));
    if (profileResponse) dispatch(setProfile({ space: space.id, profile: profileResponse }));
  }, [dispatch, space, profileResponse]);

  if (profileError && 'status' in profileError && profileError.status === 400) {
    return <Navigate to="/invitation-required" />;
  }

  if (profileError && 'status' in profileError && profileError.status === 403) {
    return <Navigate to="/auth/login" />;
  }

  return !profileLoading && space ? (
    <>
      <Outlet />
      <Overlay />
    </>
  ) : (
    <Box sx={{ textAlign: 'center' }} mt={5}>
      <CircularProgress />
    </Box>
  );
}
