import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSidebarOpen, setSidebarOpen } from '../data/sidebar.slice';

export function useSidebar() {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(selectSidebarOpen);

  const setSidebarOpenCallback = useCallback((sidebarOpen: boolean) => dispatch(setSidebarOpen({ sidebarOpen })), [dispatch]);

  return {
    sidebarOpen,
    setSidebarOpen: setSidebarOpenCallback,
  };
}
