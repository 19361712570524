import { NotificationState } from '@ikon-web/notification-types';
import { ReactNode } from 'react';
import { api } from '../api/api';
import { useAuth } from '../hooks/use-auth';
import { useSpace } from '../hooks/use-space';
import { NotificationContext } from './notification.context';

export function NotificationProvider(opts: { children?: ReactNode }) {
  const space = useSpace();
  const { user } = useAuth();

  const { data: notifications } = api.useGetNotificationsQuery({ space: space?.id, user: user.id, limit: 5 }, { skip: !space || !user, refetchOnMountOrArgChange: true, pollingInterval: 60000 });
  const [updateNotificationStates] = api.useUpdateNotificationStatesMutation();
  const hasNewNotifications = notifications?.results.some((notification) => notification.state === NotificationState.Unread);

  function setNotificationsRead() {
    const unreadNotifications = notifications?.results.filter((notification) => notification.state === NotificationState.Unread);
    if (unreadNotifications?.length) {
      updateNotificationStates({
        notifications: unreadNotifications.map((notification) => notification.id),
        state: NotificationState.Read,
      });
    }
  }

  return <NotificationContext.Provider value={{ hasNewNotifications, notifications: notifications?.results ?? [], setNotificationsRead }}>{opts.children}</NotificationContext.Provider>;
}
