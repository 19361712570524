import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

const initialState: {
  roomIds: string[];
  currentIndex: number | null;
} = {
  roomIds: [],
  currentIndex: null,
};

const roomStackSlice = createSlice({
  name: 'roomStack',
  initialState,
  reducers: {
    pushRoom: (state, { payload: { roomId } }: PayloadAction<{ roomId: string }>) => {
      // If the current room is the same as the new room, don't push it
      if (state.roomIds.length && state.roomIds.at(state.currentIndex) === roomId) return;
      if (!state.roomIds.length || state.roomIds.at(-1) !== roomId) state.roomIds.push(roomId);

      state.currentIndex = state.roomIds.length - 1;
    },
    goPrevious: (state) => {
      if (state.currentIndex !== null && state.currentIndex > 0) {
        state.currentIndex--;
      }
    },
    goNext: (state) => {
      if (state.currentIndex !== null && state.currentIndex < state.roomIds.length - 1) {
        state.currentIndex++;
      }
    },
    reset: () => initialState,
  },
});

export const roomStackReducer = roomStackSlice.reducer;

export const { pushRoom, goPrevious, goNext, reset: resetRoomStack } = roomStackSlice.actions;

export const selectState = (state: RootState) => state.roomStack;
export const selectPreviousRoom = createSelector(selectState, (state) => (state.currentIndex !== null && state.currentIndex > 0 ? state.roomIds[state.currentIndex - 1] : null));
export const selectNextRoom = createSelector(selectState, (state) => (state.currentIndex !== null && state.currentIndex < state.roomIds.length - 1 ? state.roomIds[state.currentIndex + 1] : null));
