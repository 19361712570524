import { Box, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useEmailLogin } from './use-email-login';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

export function EmailLogin(props: { onRequestLogin: () => void }) {
  const navigate = useNavigate();
  const emailLogin = useEmailLogin();

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        await emailLogin.request(values.email);
        props.onRequestLogin();
        navigate('/auth/email-verify', { state: { email: values.email } });
      } catch (err: any) {
        console.log('[Meet] Failed to login with email', err);
      }
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <TextField
        autoFocus
        name="email"
        label="Email"
        type="email"
        fullWidth
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        sx={{ mb: 3 }}
      ></TextField>
      <Button
        variant="contained"
        disableElevation
        fullWidth
        disabled={emailLogin.requestLoading}
        type="submit"
        sx={{
          color: '#000',
          background: 'linear-gradient(319deg, #49D6EA 17.18%, #83C3FC 40.79%, #9196FF 74.77%, #9196FF 74.77%)',
          borderRadius: '14px',
        }}
      >
        Login
      </Button>
    </Box>
  );
}
