import { Room, RoomTemplatePublic, RoomTemplateType, SpacePublic } from '@ikon-web/space-types';
import { User } from '@ikon-web/user-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { AppBar, Box, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DeployedCodeIcon } from '../../../../components/icons/deployed-code-icon';
import { MenuItem } from '../../../../components/menu/menu-item';
import { useRooms } from '../../../../shared/hooks/use-rooms';
import { useSidebar } from '../../../../shared/hooks/use-sidebar';
import { useSpace } from '../../../../shared/hooks/use-space';
import { featureTemporalRooms } from '../../../../shared/utils/env.utils';
import { isIframe } from '../../../../shared/utils/iframe.utils';
import { RoomName } from '../../../room/room-name';
import { SettingsMobile } from './settings-mobile';

export function SpaceSidebarMobile(props: {
  space: SpacePublic;
  rooms: Room[];
  roomTemplates: RoomTemplatePublic[];
  activeRoomCode: string;
  user: User;
  onRoomCreate: (roomTemplate: RoomTemplatePublic, local?: boolean) => void;
}) {
  const { sidebarOpen, setSidebarOpen } = useSidebar();

  const space = useSpace();
  const logoUrl = space?.assets.find((a) => a.type === 'space-logo')?.url;

  const temporalRoomTemplates = useMemo(() => props.roomTemplates?.filter((rt) => rt.type === RoomTemplateType.Temporal).map((roomTemplate) => roomTemplate.id) || [], [props.roomTemplates]);

  const handleOpenFullExperience = () => {
    window.open(`/`, '_blank');
  };

  const handleRoomTemplateClick = (roomTemplate: RoomTemplatePublic) => {
    setSidebarOpen(false);
    props.onRoomCreate(roomTemplate, false);
  };

  const { mainRooms } = useRooms();

  return sidebarOpen ? (
    <Box
      sx={{
        position: 'absolute',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        zIndex: 1299,
        backgroundColor: 'background.default',
        backdropFilter: 'blur(8px)',
      }}
    >
      <Box sx={{ width: '100%', height: '100%' }}>
        <AppBar position="sticky" elevation={0} sx={{ background: 'none' }}>
          <Toolbar disableGutters sx={{ ml: 2, mr: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
            <IconButton size="small" sx={{ borderRadius: '8px', position: 'absolute', left: 0, top: 0, bottom: 0 }} onClick={() => setSidebarOpen(false)}>
              <ChevronLeftIcon />
            </IconButton>

            {logoUrl ? <Box component="img" src={logoUrl} sx={{ height: '24px' }} /> : <DeployedCodeIcon />}
            <Typography variant="subtitle2" component="h1" sx={{ color: 'text.primary', fontWeight: 400, display: 'inline' }}>
              {props.space.name}
            </Typography>
          </Toolbar>
        </AppBar>

        <Box
          sx={{
            pt: 1,
            pb: 5,
            px: 2,
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            useFlexGap
            sx={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                p: 0.5,
                borderRadius: '16px',
                backgroundColor: 'surface.background.overlayTransparent',
                backdropFilter: 'blur(8px)',
              }}
            >
              <List sx={{ width: '100%', p: 0, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                {mainRooms?.length > 0 && (
                  <ListItem key={mainRooms[0].id} disablePadding>
                    <MenuItem component={RouterLink} to={mainRooms[0].code} state={{ interaction: true }} selected={mainRooms[0].code === props.activeRoomCode} onClick={() => setSidebarOpen(false)}>
                      <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>
                        <RoomName room={mainRooms[0]} />
                      </ListItemText>
                    </MenuItem>
                  </ListItem>
                )}
                {props.rooms
                  ?.filter((room) => !mainRooms.some((mr) => mr.code === room.code) && !temporalRoomTemplates.includes(room.roomTemplate))
                  .map((room) => (
                    <ListItem key={room.id} disablePadding>
                      <MenuItem component={RouterLink} to={room.code} state={{ interaction: true }} selected={room.code === props.activeRoomCode} onClick={() => setSidebarOpen(false)}>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>
                          <RoomName room={room} />
                        </ListItemText>
                      </MenuItem>
                    </ListItem>
                  ))}

                {featureTemporalRooms && (
                  <>
                    <Divider sx={{ my: 0.5 }} />
                    {props.roomTemplates
                      ?.filter((roomTemplate) => roomTemplate.type === RoomTemplateType.Temporal)
                      .map((roomTemplate) => (
                        <ListItem key={roomTemplate.id} disablePadding>
                          <MenuItem onClick={() => handleRoomTemplateClick(roomTemplate)}>
                            <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>{roomTemplate.name}</ListItemText>
                          </MenuItem>
                        </ListItem>
                      ))}
                  </>
                )}
              </List>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                p: 0.5,
                borderRadius: '16px',
                backgroundColor: 'surface.background.overlayTransparent',
                backdropFilter: 'blur(8px)',
              }}
            >
              <List sx={{ width: '100%', p: 0 }}>
                <ListItem
                  disablePadding
                  sx={{
                    '.MuiListItemSecondaryAction-root': {
                      display: 'inline-flex',
                    },
                  }}
                  secondaryAction={<ChevronRightIcon />}
                >
                  <SettingsMobile />
                </ListItem>
                {!!isIframe && (
                  <ListItem disablePadding>
                    <MenuItem onClick={handleOpenFullExperience}>
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        <OpenInBrowserIcon />
                      </ListItemIcon>
                      <ListItemText primaryTypographyProps={{ fontSize: '14px', fontWeight: '500' }}>Launch full experience</ListItemText>
                    </MenuItem>
                  </ListItem>
                )}
              </List>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  ) : null;
}
