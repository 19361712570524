import { VideoCodec, videoCodecToString } from '@ikon-web/event-shared';
import { delayMs } from '@ikon-web/utils';
import * as Sentry from '@sentry/react';
import { IkonCommand } from '../ikon-command';
import { Plugin } from '../plugin';

export class ScreenRecorderPlugin implements Plugin {
  private isReady = false;
  private isRunning = false;
  private encoder?: VideoEncoder;
  private frameCounter = 0;
  private makeKeyFrame = false;
  private configuration: VideoEncoderConfig = {
    codec: videoCodecToString(VideoCodec.Vp8),
    width: 0,
    height: 0,
    bitrate: this.getBitrate(),
    framerate: 10,
  };
  private mediaStream?: MediaStream;
  private intervalId: NodeJS.Timer | undefined;

  constructor(private ikonWorker: Worker) {
    console.debug('[ScreenRecorder] Initialise');

    this.ikonWorker.postMessage({ command: IkonCommand.InitializeScreenRecorderSender });
  }

  async close() {
    console.log('[ScreenRecorder] Close');

    this.isReady = false;
  }

  async start(): Promise<void> {
    try {
      if (this.isReady) {
        console.debug('[ScreenRecorder] Already configured');
        return;
      }

      this.mediaStream = await navigator.mediaDevices.getDisplayMedia({ video: { frameRate: 10 }, audio: false });
      const track = this.mediaStream.getVideoTracks()[0];

      // MediaStreamTrackProcessor not typed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const mediaStreamTrackProcessor = new MediaStreamTrackProcessor(track);
      const reader = mediaStreamTrackProcessor.readable.getReader();
      console.log('[ScreenRecorder] Start processing screen capture');

      // Seems final settings are not known until some delay...
      await delayMs(200);

      const settings = track.getSettings();
      let width = settings.width;
      let height = settings.height;
      if (!width || !height) {
        width = 1280;
        height = 720;
      }

      this.createEncoder(width, height);
      if (!this.encoder) {
        console.error('[ScreenRecorder] Video encoder not configured');
        return;
      }
      this.startVideoSizeCheck(track);

      this.isReady = true;
      this.isRunning = true;
      while (this.isRunning) {
        const result = await reader.read();
        if (result.done) {
          console.error('[ScreenRecorder] Screen capture API stopped recording');
          break;
        }

        const frame = result.value;
        if (this.encoder.encodeQueueSize > 2) {
          frame.close();
          return;
        }

        this.frameCounter++;
        const keyFrame = this.makeKeyFrame || this.frameCounter % 150 === 0;
        this.makeKeyFrame = false;
        this.encoder.encode(frame, { keyFrame });
        frame.close();
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error('[ScreenRecorder] Failed to capture screen', err);
    } finally {
      this.isReady = false;
      this.releaseEncoder();
      this.ikonWorker.postMessage({ command: IkonCommand.ScreenRecorderStop });
    }
  }

  private createEncoder(width: number, height: number) {
    console.debug('[ScreenRecorder] Configure encoder');
    this.encoder = new VideoEncoder({
      output: (chunk, metadata) => {
        if (metadata?.decoderConfig) {
          console.debug(`[ScreenRecorder] Decoder metadata ${JSON.stringify(metadata.decoderConfig)}`);
        }

        const chunkData = new Uint8Array(chunk.byteLength);
        chunk.copyTo(chunkData);
        this.ikonWorker.postMessage({ command: IkonCommand.ScreenRecorderFrame, data: { frame: chunk, frameCounter: this.frameCounter } });
      },
      error: (e) => {
        console.error('[ScreenRecorder] Frame encoding failed', e.message);
      },
    });

    this.setConfiguration(width, height);

    console.debug(`[ScreenRecorder] Configure encoder completed, configuration: ${JSON.stringify(this.configuration)}`);
  }

  private releaseEncoder() {
    console.debug('[ScreenRecorder] Release encoder');

    this.closeVideoSizeCheck();

    this.mediaStream?.getTracks().forEach((track) => track.stop());
    this.mediaStream = undefined;

    this.encoder?.close();
    this.encoder = undefined;
  }

  private startVideoSizeCheck(track: MediaStreamTrack) {
    if (this.intervalId) return;
    this.intervalId = setInterval(() => {
      try {
        const settings = track.getSettings();
        if (settings.width && settings.height && (settings.width !== this.configuration.width || settings.height !== this.configuration.height)) {
          this.setConfiguration(settings.width, settings.height);
        }
      } catch (err) {
        this.closeVideoSizeCheck();
      }
    }, 1000);
  }

  private closeVideoSizeCheck() {
    if (this.intervalId) {
      clearInterval(this.intervalId as unknown as number);
      this.intervalId = undefined;
    }
  }

  private setConfiguration(width: number, height: number) {
    this.configuration.width = width;
    this.configuration.height = height;
    this.configuration.bitrate = this.getBitrate(width, height);

    this.makeKeyFrame = true;
    this.encoder?.configure(this.configuration);
    this.ikonWorker.postMessage({ command: IkonCommand.ScreenRecorderConfiguration, data: this.configuration });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private getBitrate(width?: number, height?: number) {
    if (width && width > 1000) return 2_000_000;
    return 1_000_000;
  }
}
