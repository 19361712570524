import { UiContainerModel, UiElementListItem, UiElementType, UiElementWrapper } from '@ikon-web/ikon-client';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

export function UiContainerDefinitionList({ container }: { container: UiContainerModel }) {
  return (
    <Stack direction="column" spacing={2} useFlexGap>
      {container.elements
        .filter((element) => element.type === UiElementType.ListItem)
        .map((element: UiElementWrapper, index) => {
          const { name, text } = element.element as UiElementListItem;
          return (
            <Stack key={index} direction="row">
              <Box sx={{ width: '120px' }}>
                <Typography component="dt" variant="body2" sx={{ fontSize: '14px', color: '#8B8C92' }}>
                  {name}
                </Typography>
              </Box>
              <Box>
                <Typography component="dd" variant="body1" sx={{ fontSize: '16px' }}>
                  {text}
                </Typography>
              </Box>
            </Stack>
          );
        })}
    </Stack>
  );
}
