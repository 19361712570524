import { User } from '@ikon-web/user-types';
import { Badge, Box, Button } from '@mui/material';
import { MouseEvent, useContext } from 'react';
import { NotificationContext } from '../../shared/context/notification.context';

export function UserMenuButton(props: { user: User; handleOpen?: (event: MouseEvent<HTMLButtonElement>) => void }) {
  const { hasNewNotifications } = useContext(NotificationContext);

  return (
    <Button onClick={props.handleOpen} sx={{ minWidth: 'unset', p: 1 }} title={props.user.name}>
      <Badge color="error" variant="dot" invisible={!hasNewNotifications}>
        <Box
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '8px',
            color: 'white',
            transition: 'all 150ms ease',
            cursor: 'pointer',
            textTransform: 'uppercase',
            backgroundColor: `primary.main`,
          }}
        >
          {props.user.name?.at(0) || 'U'}
        </Box>
      </Badge>
    </Button>
  );
}
