import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Button, Divider, Switch, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BottomMenu } from '../../../../components/bottom-menu';
import { PrivacyPolicyModal } from '../../../../components/privacy-policy-modal';
import { TermsOfServiceModal } from '../../../../components/terms-of-service-modal';
import { HAS_DEBUG_OVERLAY } from '../../../../shared/constants';
import { useIkon } from '../../../../shared/hooks/use-ikon';
import { openDebugOverlay } from '../../../debug-overlay/debug-overlay.slice';
import { SettingsLanguageMobile } from './settings-language-mobile';
import { SettingsMenuButton } from './settings-menu-button';
import { SettingsThemeMobile } from './settings-theme-mobile';

export function SettingsMobile() {
  const dispatch = useDispatch();
  const ikon = useIkon();

  const [enableAudio, setEnableAudio] = useState(true);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [termsOfServiceOpen, setTermsOfServiceOpen] = useState(false);

  return (
    <BottomMenu button={<SettingsMenuButton />}>
      <Stack direction="column" spacing={0.5} useFlexGap>
        <SettingsLanguageMobile />
        <SettingsThemeMobile />

        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{
            p: 1,
            color: 'text.secondary',
          }}
        >
          <VolumeUpIcon style={{ width: '20px', height: '20px' }} />
          <Typography variant="body2" sx={{ flex: 1 }}>
            Audio
          </Typography>
          <Switch
            size="small"
            checked={enableAudio}
            onChange={(event) => {
              setEnableAudio(event.target.checked);
              ikon.enableAudio(event.target.checked);
            }}
          />
        </Stack>

        <Divider />

        <TermsOfServiceModal open={termsOfServiceOpen} close={() => setTermsOfServiceOpen(false)} />
        <PrivacyPolicyModal open={privacyPolicyOpen} close={() => setPrivacyPolicyOpen(false)} />

        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{
            p: 1,
            color: 'text.secondary',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="text"
            onClick={() => {
              setTermsOfServiceOpen(true);
            }}
            sx={{
              fontSize: '10px',
              lineHeight: '14px',
            }}
          >
            Terms of Service
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setPrivacyPolicyOpen(true);
            }}
            sx={{
              fontSize: '10px',
              lineHeight: '14px',
            }}
          >
            Privacy Policy
          </Button>
        </Stack>

        {HAS_DEBUG_OVERLAY && <Button onClick={() => dispatch(openDebugOverlay())}>Debug overlay</Button>}
      </Stack>
    </BottomMenu>
  );
}
