import { IkonCommand } from '../ikon-command';
import { Plugin } from '../plugin';

export class BlobRendererPlugin implements Plugin {
  constructor(
    private ikonWorker: Worker,
    canvas: HTMLCanvasElement,
  ) {
    console.debug('[BlobRendererPlugin] Initialise');

    const offscreenCanvas = canvas.transferControlToOffscreen();
    ikonWorker.postMessage(
      {
        command: IkonCommand.InitializeBlobRenderer,
        canvas: offscreenCanvas,
      },
      { transfer: [offscreenCanvas] },
    );
  }

  async close() {
    console.debug('[BlobRendererPlugin] Close');
    this.ikonWorker.postMessage({ command: IkonCommand.CloseBlobRenderer });
  }
}
