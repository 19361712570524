import { AnalyticsEventsItem } from '@ikon-web/event-shared';
import { useCallback, useEffect, useRef } from 'react';
import { api } from '../api/api';
import { useAuth } from './use-auth';
import { useSpace } from './use-space';

export function useEvents() {
  const events = useRef<AnalyticsEventsItem[]>([]);
  const { user, isAuthenticated } = useAuth();
  const space = useSpace();
  const [sendEvent] = api.useEventMutation();

  useEffect(() => {
    if (space && isAuthenticated && events.current.length) {
      sendEvent({ event: { Events: events.current }, space: space.id });
      events.current = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space, isAuthenticated]);

  const sendAnalyticsEvent = useCallback(
    (eventName: string, message: string, parameters: Record<string, string> = {}) => {
      const event = { EventName: eventName, Message: message, Parameters: JSON.stringify({ user: user?.id, ...parameters }), Time: new Date().toISOString() };
      if (space && isAuthenticated) {
        sendEvent({ event: { Events: [event] }, space: space.id });
      } else {
        events.current.push(event);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [space, isAuthenticated],
  );

  return {
    sendAnalytics: sendAnalyticsEvent,
  };
}
