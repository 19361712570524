import { Box } from '@mui/material';
import { PrivacyPolicy } from '../../components/privacy-policy';

export function PrivacyPolicyPage() {
  return (
    <Box sx={{ p: 4, height: '100dvh', overflow: 'auto' }}>
      <PrivacyPolicy />
    </Box>
  );
}
