import * as Sentry from '@sentry/react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { App } from './app';
import { Authorised } from './components/authorised';
import { AuthPage } from './features/auth/auth.page';
import { EmailVerifyPage } from './features/auth/email-verify.page';
import { LoginPage } from './features/auth/login.page';
import { LogoutPage } from './features/auth/logout.page';
import { InvitationRequiredPage } from './features/invitation/invitation-required.page';
import { InvitationPage } from './features/invitation/invitation.page';
import { PrivacyPolicyPage } from './features/legal/privacy-policy.page';
import { TermsOfServicePage } from './features/legal/terms-of-service.page';
import { RoomLocalPage } from './features/room/room-local.page';
import { RoomSelectInfo } from './features/room/room-select-info';
import { RoomPage } from './features/room/room.page';
import { SpaceProvider } from './features/space/space-provider';
import { SpacePage } from './features/space/space.page';
import './styles.css';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN_MEET || 'https://bf6c0ebb62ac360de05d0c943ec4a085@o4507067472609280.ingest.us.sentry.io/4507067475361792';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: import.meta.env.VITE_IKON_ENVIRONMENT,
  integrations: [],
});

const router = createBrowserRouter([
  {
    path: '/',
    Component: App,
    children: [
      { path: '', Component: () => <Navigate to="/rooms" /> },
      { path: 'invitation-required', Component: InvitationRequiredPage },

      {
        path: 'auth',
        Component: AuthPage,
        children: [
          { path: 'login', Component: LoginPage },
          { path: 'logout', Component: LogoutPage },
          { path: 'email-verify', Component: EmailVerifyPage },
        ],
      },

      {
        Component: Authorised,
        children: [
          { path: 'invitation/:code', Component: InvitationPage },
          {
            Component: SpaceProvider,
            children: [
              {
                path: 'rooms',
                Component: SpacePage,
                children: [
                  { path: '', Component: RoomSelectInfo },
                  { path: 'local', Component: RoomLocalPage },
                  { path: ':code', Component: RoomPage },
                ],
              },
            ],
          },
        ],
      },

      { path: 'terms-of-service', Component: TermsOfServicePage },
      { path: 'privacy-policy', Component: PrivacyPolicyPage },

      {
        path: '*',
        Component: () => <Navigate to="/" />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RouterProvider router={router} />);

// trigger 4
