import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { UiContainer } from '../../components/ikon/ui-container';
import { selectSecondScreenContainerIds } from '../../shared/data/container.slice';

export function SecondScreen() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [previousId, setPreviousId] = useState<string | null>(null);
  const rootContainerIds = useSelector(selectSecondScreenContainerIds);

  useEffect(() => {
    if (rootContainerIds.length) {
      if (rootContainerIds.at(0) !== previousId) {
        containerRef.current?.scrollTo(0, 0);
      }
      setPreviousId(rootContainerIds.at(0));
    }
  }, [rootContainerIds, previousId]);

  if (!rootContainerIds?.length) return null;

  return (
    <Box
      ref={containerRef}
      className="ikon-scrollbar"
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        p: 3,
        margin: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
      }}
    >
      {rootContainerIds.map((rootContainerId) => (
        <UiContainer containerId={rootContainerId} myMessage={false} key={rootContainerId} />
      ))}
    </Box>
  );
}
