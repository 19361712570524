import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../shared/hooks/use-auth';
import { featureDirectLogin } from '../shared/utils/env.utils';

export const Authorised = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isAuthenticated, isLoading, loginProvider } = useAuth();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      if (featureDirectLogin) {
        loginProvider('google');
        return;
      }

      console.log('[Meet] Not authenticated, redirecting to login');
      navigate('/auth/login', { state: { redirect: window.location.pathname, invalidToken: searchParams.has('error') } });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isAuthenticated, isLoading, loginProvider]);

  if (isLoading || !isAuthenticated) {
    return (
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return <Outlet />;
};
